<template>
  <div class="performance-category">
    <h6 class="performance-category__title">
      {{ category }}
    </h6>

    <p
      v-if="badgeText"
      class="performance-category__badge"
    >
      {{ badgeText }}
    </p>

    <div class="performance-category__results">
      <RadialProgressBar
        :completed-steps="performance.value"
        :diameter="164"
        inner-stroke-color="#F0F0F2"
        :start-color="performance.color"
        :stop-color="performance.color"
        :stroke-width="10"
        :total-steps="100"
      >
        <h2>{{ performance.value }}%</h2>

        <h5 class="performance-category__percentage">
          {{ performance.label }}
        </h5>
      </RadialProgressBar>

      <div class="performance-category__general-result">
        <SasInfo
          :label="generalPerformance.label"
          :value="{
            text: generalPerformance.value,
            type: 7,
            color: '#78809A',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
import SasInfo from '@/components/global/SasInfo'

export default {
  name: 'PerformanceCategory',

  components: {
    RadialProgressBar,
    SasInfo,
  },

  props: {
    category: {
      type: String,
      default: '',
    },

    badgeText: {
      type: String,
      default: '',
    },

    performance: {
      type: Object,
      required: true,
      default: () => ({
        value: 0,
        label: '',
        color: 'black',
      }),
    },

    generalPerformance: {
      type: Object,
      required: true,
      default: () => ({
        value: 0,
        label: '',
      }),
    },
  },
}
</script>

<style lang="sass">

.performance-category

  &__title
    height: 48px
    font-weight: $font-weight-semi-bold

  &__badge
    font-size: 14px
    font-weight: 600
    width: fit-content
    color: $color-ink-light
    padding: 4px 16px
    box-sizing: border-box
    border: 1px solid $color-ink-lighter
    border-radius: 8px
    margin: 24px 0px 0px 0px

  &__results
    display: flex
    align-items: center
    margin-top: 24px
    justify-content: space-between

    +mq-l
      flex-direction: column

  &__percentage
    font-size: 14px
    max-width: 72px
    color: $color-ink-light

  &__general-result
    padding: 8px 16px
    box-sizing: border-box
    border-radius: 8px
    background: $color-ink-lightest
    margin-right: 24px

    .info-right__text--custom-tag
      font-weight: $font-weight-semi-bold

    +mq-l
      margin-top: 16px
      width: 100%
      margin-right: unset

</style>
