<template>
  <Drawer
    v-if="questionDrawerVisible"
    class="drawer-questions"
    :size="sizeDrawer"
    @close="toggleQuestionDrawer(false)"
  >
    <div class="drawer-questions">
      <div class="drawer-questions__header">
        <div class="drawer-questions__header__title">
          <h3 class="drawer-questions__header__title__text">
            {{ assessment.name }}
          </h3>
          <s-button
            icon="x"
            variation="tertiary"
            @click="toggleQuestionDrawer(false)"
          />
        </div>
      </div>
      <div class="drawer-questions__content">
        <ProgressRingChart
          v-if="!typeSlugOlympics"
          :info-chart="infoChartHits"
          is-horizontal
          is-limited
          :loading="loading"
        />
        <AssessmentQuestionsTable />
      </div>
    </div>
    <QuestionDetailsDrawer v-if="itemDetailsVisible" />
  </Drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import AssessmentQuestionsTable from '@/components/AssessmentQuestionsTable'
import ProgressRingChart from '@/components/ProgressRingChart'
import QuestionDetailsDrawer from '@/components/QuestionDetailsDrawer'

export default {
  name: 'QuestionsDrawer',
  components: {
    AssessmentQuestionsTable,
    ProgressRingChart,
    QuestionDetailsDrawer,
  },
  data() {
    return {
      loading: false,
      hitsQuestionChart: {
        correct: '0',
        total: '0',
      },
    }
  },
  computed: {
    ...mapGetters([
      'assessment',
      'questionDrawerVisible',
      'selectedQuestion',
    ]),
    itemDetailsVisible() {
      return Object.keys(this.selectedQuestion).length > 0
    },
    typeSlugOlympics() {
      const { typeSlug } = this.assessment

      return typeSlug === 'olimpiadas'
    },
    sizeDrawer() {
      return this.itemDetailsVisible ? '100%'
        : {
          x_large: '60%',
          small: '100%',
          x_small: '100%',
        }[this.$mq] || '90%'
    },
    infoChartHits() {
      return {
        total: this.hitsQuestionChart.total,
        value: this.hitsQuestionChart.correct,
        label: this.$t(
          'pages.finalResult.hitsQuestions.label',
          [this.hitsQuestionChart.correct, this.hitsQuestionChart.total],
        ),
      }
    },
  },
  async created() {
    document.body.style.overflowY = 'hidden'
    const { assessmentId } = this.$route.params

    try {
      this.loading = true
      const [hitsChart] = await Promise.all(
        [
          this.getAssessment(assessmentId),
          this.getHitsQuestions({ assessmentId }),
        ],
      )
      this.hitsQuestionChart = hitsChart
    } catch (err) {
      this.$toasted.global.error({
        message: this.$t('components.questionDrawer.error'),
      })
    } finally {
      this.loading = false
    }
  },
  beforeDestroy() {
    document.body.style.overflowY = 'scroll'
  },
  methods: {
    ...mapActions([
      'getAssessment',
      'toggleQuestionDrawer',
      'setPagedQuestions',
      'getHitsQuestions',
    ]),
    selectQuestion(question) {
      this.itemDetailsVisible = question !== null
    },
  },
}
</script>

<style lang="sass" scoped>
.drawer-questions
  background: $color-ice

  &__header
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    background: $color-white

    &__title
      width: 100%
      display: flex
      justify-content: space-between
      padding: $size-xs $size-s
      border-bottom: 1px solid $color-ink-lightest

      +mq-m--mf
        padding: $size-m $size-l

      &__text
        font-size: $font-size-heading-4

  &__content
    padding: $size-s

    +mq-s--mf
      padding: $size-m $size-m
</style>
