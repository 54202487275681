<template>
  <div class="exam-results__performance">
    <h3>Desempenho por {{ performanceType }}</h3>

    <ExamResultPerformanceCategoryCard
      v-if="loading"
      class="exam-results__performance--loading"
      :loading="loading"
    />

    <div
      v-else
      class="exam-results__performance-categories"
    >
      <SasButton
        class="performance-categories__pagination"
        size="big"
        theme="float"
        @click="back()"
      >
        <Icon
          type="chevron-left"
        />
      </SasButton>

      <Carousel
        v-model="slideIndex"
        pagination-active-color="#4D8AF0"
        pagination-color="#B8BED5"
        :per-page="$mq === 'x_small' || $mq === 'small' ? 1 : 2"
        @pageChange="changeSlideIndex()"
      >
        <Slide
          v-for="(performanceCategory, index) in performanceCategories"
          :key="index"
        >
          <ExamResultPerformanceCategoryCard
            color="#4D8AF0"
            :performance-category="performanceCategory"
          />
        </Slide>
      </Carousel>

      <SasButton
        class="performance-categories__pagination"
        size="big"
        theme="float"
        @click="next()"
      >
        <Icon
          type="chevron-right"
        />
      </SasButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'

import ExamResultPerformanceCategoryCard from '@/components/ExamResultPerformanceCategoryCard'

export default {
  name: 'ExamResultPerformanceCategory',

  components: {
    ExamResultPerformanceCategoryCard,
    Carousel,
    Slide,
  },

  props: {
    assessmentId: {
      type: [String, Number],
      required: true,
    },

    examId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      performanceCategories: [],
      slideIndex: 0,
    }
  },

  computed: {
    ...mapGetters(['exam']),

    performanceType() {
      return this.exam.name === 'Matemática'
        ? 'Unidade Temática'
        : 'Campo de Atuação'
    },
  },

  created() {
    this.load()
  },

  methods: {
    ...mapActions(['getPerformanceByCategories']),

    async load() {
      this.loading = true
      await this.loadPerformanceCategories()
      this.sortPerformanceCategories()
      this.loading = false
    },

    async loadPerformanceCategories() {
      const { assessmentId, examId } = this
      this.performanceCategories = await this.getPerformanceByCategories({
        assessmentId,
        examId,
      })
    },

    sortPerformanceCategories() {
      this.performanceCategories.sort(
        (a, b) => (
          b.grade !== a.grade
            ? b.grade - a.grade
            : b.itemsCount - a.itemsCount
        ),
      )
    },

    back() {
      if (this.slideIndex) {
        this.slideIndex -= 1
      }
    },

    next() {
      const maxIndex = this.$mq !== 'small' && this.$mq !== 'x_small'
        // eslint-disable-next-line no-magic-numbers
        ? (this.performanceCategories.length % 2) + 1
        : this.performanceCategories.length - 1
      if (this.slideIndex < maxIndex) {
        this.slideIndex += 1
      }
    },

    changeSlideIndex(index) {
      this.slideIndex = index
    },
  },
}
</script>

<style lang="sass">

.exam-results__performance
  margin-top: 48px

  &-categories
    display: flex
    align-items: center
    justify-content: center

    .performance-categories

      &__pagination
        padding: 0

      &__badge
        border-top-left-radius: 8px
        border-top-right-radius: 8px
        padding: 12px 16px

        +mq-m

          h5
            font-size: 13px

  &--loading
    margin: 24px 0px

  .VueCarousel
    width: 90%

    &-dot
      margin-top: 0px !important

    &-dot-container
      margin-top: 0px !important
      margin-bottom: 16px

    &-slide
      flex-basis: unset
      width: 50%

      &:nth-child(odd)
        padding: 24px 2.5% 8px 2.5%

      &:nth-child(even)
        padding: 24px 2.5% 8px 2.5%

      +mq-s
        width: 100%
        padding: 24px 7.5% 8px 7.5% !important

</style>
