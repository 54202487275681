<template>
  <ScoreCard
    :body-info="rankingBoard.main"
    :error="hasError"
    :footer-info="rankingBoard.footer"
    :loading="loading"
    title="Sua classificação"
    @refresh="reload()"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ScoreCard from '@/components/ScoreCard'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'RankedGeneralRanking',

  components: {
    ScoreCard,
  },

  mixins: [mediaQueries],

  props: {
    isHighSchool: {
      type: Boolean,
      required: true,
    },

    examId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      studentRankings: [
        {
          name: '',
          score: 0,
          globalRanking: 0,
          schoolRanking: 0,
        },
      ],
      studentParticipation: {
        schoolCount: 0,
        globalCount: 0,
      },
      basicRankingTypes: ['', 'classic', 'raw'],
      advancedRankingTypes: ['', 'irt', 'standard'],
      loading: false,
      hasError: false,
    }
  },

  computed: {
    ...mapGetters([
      'assessment',
      'isDigitalEnem',
    ]),

    rankingTypes() {
      return this.studentRankings.map((score) => score.name)
    },

    basicRankingType() {
      return this.rankingTypes.find(
        (type) => this.basicRankingTypes.includes(type.toLowerCase()),
      )
    },

    advancedRankingType() {
      return this.rankingTypes.find(
        (type) => this.advancedRankingTypes.includes(type.toLowerCase()),
      )
    },

    hasAdvancedRankingType() {
      return this.advancedRankingType
    },

    rankingType() {
      return this.hasAdvancedRankingType
        ? this.advancedRankingType
        : this.basicRankingType
    },

    ranking() {
      return this.studentRankings.find(
        (ranking) => ranking.name === this.rankingType,
      )
    },

    rankingInfo() {
      const { schoolRanking, globalRanking } = this.ranking

      return {
        school: {
          label: 'Na Escola',
          size: 'x-large',
          text: `${schoolRanking.toLocaleString('pt-br')}º`,
          icon: {
            name: 'bar-chart-2',
            color: this.$tokens.color_primary,
          },
        },
        global: {
          label: 'Nacional',
          size: 'x-large',
          text: `${globalRanking.toLocaleString('pt-br')}º`,
          icon: {
            name: 'bar-chart-2',
            color: this.$tokens.color_primary,
          },
        },
      }
    },

    participationInfo() {
      const schoolCount = this.studentParticipation.schoolsCount
        || this.studentParticipation.schoolCount

      const globalCount = this.studentParticipation.studentsCount
        || this.studentParticipation.globalCount

      return {
        school: {
          label: 'Participantes da Escola',
          text: schoolCount.toLocaleString('pt-br'),
          size: 'large',
        },
        global: {
          label: 'Participantes Nacional',
          text: globalCount.toLocaleString('pt-br'),
          size: 'large',
        },
      }
    },

    verticalRankingBoard() {
      return {
        main: [this.rankingInfo.school, this.rankingInfo.global],
        footer: [this.participationInfo.school, this.participationInfo.global],
      }
    },

    horizontalRankingBoard() {
      return {
        main: [
          this.rankingInfo.school,
          this.participationInfo.school,
          this.rankingInfo.global,
          this.participationInfo.global,
        ],
        footer: null,
      }
    },

    rankingBoard() {
      return this.hasAdvancedRankingType && !this.isDigitalEnem
        ? this.horizontalRankingBoard
        : this.verticalRankingBoard
    },
  },

  watch: {
    studentRankings() {
      this.$emit('update-layout', this.hasAdvancedRankingType && !this.isDigitalEnem)
    },
  },

  created() {
    this.loadRanking()
  },

  methods: {
    ...mapActions([
      'getGeneralRankings',
      'getParticipation',
      'getGeneralRankingsByAssessment',
      'getParticipationByAssessment',
    ]),

    reload() {
      this.loadRanking(true)
    },

    async loadRanking(force = false) {
      this.hasError = false
      this.loading = true
      try {
        let params = null
        let actions = null

        if (this.isDigitalEnem) {
          params = {
            assessmentId: this.assessment.id,
            force,
          }
          actions = {
            ranking: 'getGeneralRankingsByAssessment',
            participation: 'getParticipationByAssessment',
          }

          const [rankingsData, participationData] = await Promise.all([
            this[actions.ranking](params),
            this[actions.participation](params),
          ])

          this.studentRankings = rankingsData
          this.studentParticipation = {
            schoolCount: participationData.participation.schoolCount,
            globalCount: participationData.globalParticipation.studentsCount,
          }
        } else {
          params = {
            examId: this.examId,
            force,
          }
          actions = {
            ranking: 'getGeneralRankings',
            participation: 'getParticipation',
          }

          const [rankingsData, participationData] = await Promise.all([
            this[actions.ranking](params),
            this[actions.participation](params),
          ])

          this.studentRankings = rankingsData

          this.studentParticipation = {
            schoolCount: participationData.schoolCount,
            globalCount: participationData.globalCount,
          }
        }
      } catch (error) {
        this.hasError = true
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
