var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Box',{staticClass:"exam-results__performance-card",attrs:{"padding":"0"}},[_vm._t("top"),(_vm.loading)?_c('div',{staticClass:"performance-card__loading"},[_c('Spinner')],1):_c('div',{staticClass:"performance-card__body"},[_c('div',{staticClass:"performance-card__left"},[_c('PerformanceCategory',{attrs:{"category":_vm.performanceCategory.name,"general-performance":{
          value: ("" + (_vm.$tc(
            'components.performanceCategoryCard.total',
            _vm.performanceCategory.hitsCount,
            _vm.performanceCategory.itemsCount
          ))),
          label: 'Você acertou',
        },"performance":{
          value: _vm.getPercentage(_vm.performanceCategory.grade),
          label: 'Acertos',
          color: _vm.color,
        }}}),(_vm.performanceCategory.subcategories.length)?_c('SasButton',{staticClass:"performance-card__toggle",attrs:{"theme":"float"},on:{"click":function($event){return _vm.toggleSubcategories()}}},[_c('Icon',{attrs:{"slot":"left","type":_vm.subcategoriesVisibility ? 'minus-circle' : 'plus-circle'},slot:"left"}),_c('div',{staticClass:"performance-card__toggle-label"},[_vm._v(" Ver Práticas de Linguagem ")])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSubCategoryVisible),expression:"isSubCategoryVisible"}],staticClass:"performance-card__subcategories"},_vm._l((_vm.performanceCategory.subcategories),function(subcategory){return _c('PerformanceSubcategory',{key:subcategory.name,attrs:{"performance":_vm.getPercentage(subcategory.grade),"subcategory":subcategory.name}})}),1)],1),_vm._t("right")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }