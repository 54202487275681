<template>
  <InfoCard
    :class="[
      {
        'info-card--horizontal': isHorizontal,
        '--limited': isLimited,
      },
    ]"
    :error="error"
    :justify="{ body: 'center' }"
    :padding="paddingBody"
    @refresh="$emit('refresh')"
  >
    <transition mode="out-in">
      <Spinner
        v-if="loading"
      />
      <ProgressRing
        v-else
        :completed-steps="chartValue"
        :diameter="120"
        :inner-stroke-color="$tokens.color_ink_lightest"
        :start-color="$tokens.color_primary_light"
        :stop-color="$tokens.color_primary_light"
        :stroke-width="8"
        :total-steps="total"
      >
        <h3 class="engagement-chart__percentage">
          {{ percentage }}<h4 class="engagement-chart__percentage__label">
            %
          </h4>
        </h3>
      </ProgressRing>
    </transition>
    <div
      v-if="isHorizontal"
      class="info-card--horizontal__title"
    >
      <h3>{{ title }}</h3>
      <span v-if="infoChart.label">
        {{ infoChart.label }}
      </span>
    </div>
  </InfoCard>
</template>

<script>
import ProgressRing from 'vue-radial-progress'
import InfoCard from '@/components/InfoCard'

export default {
  name: 'ProgressRingChart',

  components: {
    InfoCard,
    ProgressRing,
  },

  props: {
    title: {
      type: String,
      default: 'Acertos',
    },

    isHorizontal: Boolean,

    error: Boolean,

    infoChart: {
      type: Object,
      required: true,
      default: () => ({
        total: '0',
        value: '0',
        label: '',
      }),
    },

    loading: Boolean,

    isLimited: Boolean,
  },

  computed: {

    paddingBody() {
      const padding = this.isHorizontal
        ? '15px'
        : '0 32px 16px'

      return { body: padding }
    },

    total() {
      return this.infoChart.total || 0
    },

    chartValue() {
      return this.infoChart.value || 0
    },

    percentage() {
      const percentageModifier = 100

      return Math.round((this.chartValue / this.total) * percentageModifier)
    },
  },
}
</script>

<style lang="sass">
.info-card

  &.--limited
    +mq-s--mf
      max-width: 300px

  &--horizontal
    .info-card__header
      display: none

    &__title
      align-self: center
      margin-left: $size-xxs

.engagement-chart__percentage__label
  display: inline
</style>
