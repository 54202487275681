<template>
  <Drawer
    v-if="itemDetailsVisible"
    class="question-details-drawer"
    :size="sizeDrawerDetails"
    @close="setSelectedQuestion()"
  >
    <div class="drawer-details-questions">
      <div class="drawer-details-questions__header">
        <div class="drawer-details-questions__header__title">
          <h3
            v-if="!loading"
            class="drawer-details-questions__header__title__text"
          >
            {{ $t('components.questionDetails.question', [question.order]) }}
          </h3>

          <skeleton-loader
            v-else
            class="skeleton-badge"
            :height="$tokens.size_l"
            :width="$tokens.size_xxl"
          />

          <div class="drawer-details-questions__header__title__actions">
            <div
              v-if="resultTypeStudent"
              class="drawer-details-questions__header__title__actions__status"
            >
              <SBadge
                v-if="!loading"
                :variation="badgeStudentQuestion()"
              >
                <icon :type="iconStudentAnswer[badgeStudentQuestion()]" />
                {{ validateStudentAnswer }}
              </SBadge>
              <skeleton-loader
                v-else
                class="skeleton-badge"
                :height="$tokens.size_l"
                :width="$tokens.size_xxl"
              />
            </div>
            <div class="actions-buttons">
              <s-button
                :disabled="loading || disabledPreviousNextQuestion(true)"
                icon="arrow-left"
                variation="tertiary"
                @click="nextPreviousQuestion(true)"
              />
              <s-button
                :disabled="loading || disabledPreviousNextQuestion(false)"
                icon="arrow-right"
                variation="tertiary"
                @click="nextPreviousQuestion(false)"
              />

              <s-button
                :disabled="loading"
                icon="x"
                variation="tertiary"
                @click="setSelectedQuestion()"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="hasHeaderInfo"
        class="drawer-details-questions__info"
      >
        <div class="drawer-details-questions__info__details">
          <label
            v-if="!loading"
            class="drawer-details-questions__info__details__text"
          >
            {{ $t('components.questionDetails.preUni') }}
          </label>
          <label
            v-if="!loading"
            class="drawer-details-questions__info__details__value"
          >
            {{
              $t(
                'components.questionDetails.preUniDescription',
                [question.lecture, question.chapter],
              )
            }}
          </label>

          <skeleton-loader
            v-else
            class="skeleton-badge"
            :height="$tokens.size_l"
            :width="$tokens.size_xxl"
          />
        </div>

        <div class="drawer-details-questions__info__details">
          <label
            v-if="!loading"
            class="drawer-details-questions__info__details__text"
          >
            {{ $t('components.questionDetails.preVest') }}
          </label>
          <label
            v-if="!loading"
            class="drawer-details-questions__info__details__value"
          >
            {{
              $t(
                'components.questionDetails.preVestDescription',
                [question.lecture, question.alternativeChapter],
              )
            }}
          </label>

          <skeleton-loader
            v-else
            class="skeleton-badge"
            :height="$tokens.size_l"
            :width="$tokens.size_xxl"
          />
        </div>
      </div>

      <div class="drawer-details-questions__content">
        <div class="drawer-details-questions__content__section">
          <h4
            v-if="!loading"
            class="drawer-details-questions__content__section__title"
          >
            {{ $t('components.questionDetails.enunciation') }}
          </h4>

          <skeleton-loader
            v-else
            class="skeleton-badge"
            :height="$tokens.size_l"
            :width="$tokens.size_xxl"
          />
        </div>

        <div
          v-if="!loading"
          class="drawer-details-questions__content__question"
          v-html="question.formattedWording"
        />

        <div
          v-else
          class="drawer-details-questions__content__question"
        >
          <skeleton-loader
            class="skeleton-badge"
            :height="$tokens.size_l"
            :width="$tokens.size_xxl"
          />
          <skeleton-loader
            class="skeleton-badge"
            :height="$tokens.size_l"
            width="60%"
          />

          <skeleton-loader
            class="skeleton-badge"
            :height="$tokens.size_l"
            width="80%"
          />
        </div>

        <div
          v-if="resultTypeStudent && !loading"
          class="drawer-details-questions__content__options"
        >
          <ExamItemOption
            v-for="({ option }, index) in studentOptions"
            :key="`question-${index}`"
            :body="option"
            :class="{
              'answer': parseInt(question.answerKey) === index,
              'correct-answer':
                parseInt(question.answerKey) === index
                && index === studentMarkedOption,
              'wrong-answer':
                parseInt(question.answerKey) !== index
                && index === studentMarkedOption,

            }"
            :letter="getOptionByOrder[index]"
            :order="index + 1"
          />
        </div>
        <div
          v-else-if="!loading"
          class="drawer-details-questions__content__options"
        >
          <ExamItemOption
            v-for="(option, index) in options"
            :key="index"
            :body="option"
            :class="{
              'answer': parseInt(question.answerKey) === index,
            }"
            :letter="getOptionByOrder[index]"
            :order="index + 1"
          />
        </div>

        <div
          v-else
          class="drawer-details-questions__content__options"
        >
          <skeleton-loader
            v-for="(item, index) in 5"
            :key="`skeleton-badge-${index}`"
            class="skeleton-badge"
            :height="$tokens.size_l"
            width="$80%"
          />
        </div>

        <div class="drawer-details-questions__content__section">
          <h4
            v-if="!loading"
            class="drawer-details-questions__content__section__title"
          >
            {{ $t('components.questionDetails.resolution') }}
          </h4>
          <skeleton-loader
            v-else
            class="skeleton-badge"
            :height="$tokens.size_l"
            :width="$tokens.size_xxl"
          />
        </div>

        <div
          v-if="!loading"
          class="drawer-details-questions__content__player"
          v-html="question.embedHtml"
        />

        <div
          v-else
          class="drawer-details-questions__content__player"
        >
          <skeleton-loader
            class="skeleton-badge--video"
            height="250px"
            width="80%"
          />

          <div class="alternative">
            <div
              v-for="item in 5"
              :key="item.id"
            >
              <skeleton-loader
                class="skeleton-badge"
                :height="$tokens.size_l"
                :width="$tokens.size_xxl"
              />

              <skeleton-loader
                class="skeleton-badge"
                :height="$tokens.size_l"
                width="80%"
              />
              <skeleton-loader
                class="skeleton-badge"
                :height="$tokens.size_l"
                width="80%"
              />
            </div>
          </div>
        </div>

        <div
          class="drawer-details-questions__content__resolution"
          v-html="question.explanation"
        />
      </div>
    </div>
  </Drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ExamItemOption from '@/components/ExamItemOption'

export default {
  name: 'QuestionDetailsDrawer',
  components: {
    ExamItemOption,
  },
  props: {
    hasHeaderInfo: Boolean,
  },
  data() {
    return {
      loading: false,
      question: {},
      getOptionByOrder: ['A', 'B', 'C', 'D', 'E'],
      statesStudentAnswer: {
        success: this.$t('components.questionDetails.hit'),
        danger: this.$t('components.questionDetails.missed'),
        warning: this.$t('components.questionDetails.invalid'),

      },
      iconStudentAnswer: {
        success: 'check',
        danger: 'x',
        warning: 'info',
      },
    }
  },

  computed: {
    ...mapGetters([
      'assessment',
      'selectedQuestion',
      'pagedQuestions',
    ]),
    itemDetailsVisible() {
      return Object.keys(this.selectedQuestion).length > 0
    },
    sizeDrawerDetails() {
      return {
        x_large: '60%',
        small: '100%',
        x_small: '100%',
      }[this.$mq] || '90%'
    },
    currentQuestion() {
      const { currentQuestion } = this.pagedQuestions

      return currentQuestion + 1
    },
    studentMarkedOption() {
      const { markedOption } = this.pagedQuestions.markedOptions.find(
        (option) => option.itemId === this.pagedQuestions.currentQuestion,
      ) || { markedOption: -2 }

      return markedOption
    },
    resultTypeStudent() {
      return this.studentMarkedOption >= -1
    },
    disabledPreviousNextQuestion() {
      return (action) => this.disabledNextPreviousQuestion(action)
    },
    options() {
      if (!this.question.formattedOptions) {
        return []
      }

      return JSON.parse(this.question.formattedOptions)
    },
    studentOptions() {
      if (!this.question.formattedOptions) {
        return []
      }

      return JSON.parse(this.question.formattedOptions).map(
        (option) => ({
          option,
        }),
      )
    },
    validateStudentAnswer() {
      return (
        this.statesStudentAnswer[this.badgeStudentQuestion()]
      )
    },
  },

  created() {
    const params = this.pagedQuestions.questions
      .find(
        (question) => question.itemId === this.pagedQuestions.currentQuestion,
      )
    this.loadQuestion(params)
  },

  methods: {
    ...mapActions([
      'setSelectedQuestion',
      'setCurrentQuestion',
      'getExamItem',
    ]),

    async loadQuestion({ itemId, examId }) {
      try {
        this.loading = true
        this.question = await this.getExamItem({
          examId,
          itemId,
        })
      } catch (error) {
        this.$toasted.global.error({
          message: this.$t('commons.errorProfiles'),
        })
      } finally {
        this.loading = false
      }
    },

    nextPreviousQuestion(action) {
      const { questions, currentQuestion } = this.pagedQuestions

      if (!questions || !currentQuestion) {
        return
      }

      const currentIndex = questions.findIndex(
        (question) => question.itemId === currentQuestion,
      )

      const question = questions[
        action ? currentIndex - 1 : currentIndex + 1
      ]
      const { itemId } = question

      this.setCurrentQuestion(itemId)
      this.loadQuestion(question)
    },

    disabledNextPreviousQuestion(action) {
      const { questions, currentQuestion } = this.pagedQuestions

      if (!questions || !currentQuestion) {
        return true
      }

      const currentIndex = questions.findIndex(
        (question) => question.itemId === currentQuestion,
      )

      if (action) {
        const FIRST_QUESTION_INDEX = 0
        const isFirstQuestion = currentIndex === FIRST_QUESTION_INDEX

        return isFirstQuestion
      }

      const isLastQuestion = currentIndex === questions.length - 1

      return isLastQuestion
    },

    badgeStudentQuestion() {
      if (this.studentMarkedOption === -1) {
        return 'warning'
      }
      if (this.studentMarkedOption === +this.question.answerKey) {
        return 'success'
      }

      return 'danger'
    },
  },
}
</script>

<style lang="sass" scoped>
.question-details-drawer
  z-index: 200

.drawer-details-questions

  &__header
    display: flex
    flex-direction: column
    align-items: flex-start
    background: $color-white
    justify-content: flex-start

    &__title
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center
      padding: $size-xs $size-s
      border-bottom: 1px solid $color-ink-lightest

      +mq-m--mf
        padding: $size-m $size-l

      &__text
        font-size: $font-size-heading-4

      &__actions
        display: flex
        flex-direction: column
        align-items: center

        +mq-m--mf
          flex-direction: row

        &__status
          margin-right: $size-xs

          ::v-deep .sas-badge__text
            display: flex
            justify-content: center
            align-items: center

            .feather
              width: $font-size-m
              margin-right: $size-xxs

  &__info
    background: $color-ice
    border-top: 1px solid $color-ink-lightest
    border-bottom: 1px solid $color-ink-lightest
    padding: $size-s
    display: flex
    flex-direction: column

    +mq-m--mf
      padding: $size-m $size-l
      flex-direction: row

    &__details
      display: flex
      flex-direction: column
      margin-right: $size-xl

      +mq-m--mf
        margin-bottom: 0

      &:first-child
        margin-bottom: $size-s

      &__text
        text-transform: uppercase
        font-size: $font-size-xs
        color: $color-ink-light

      &__value
        color: $color-ink
        font-size: $font-size-s

        +mq-m--mf
          font-size: $font-size-m

  &__content
    display: flex
    flex-direction: column
    width: 100%
    background: $color-white

    &__player
      ::v-deep iframe
        width: 100% !important
        height: 350px
        border: none
        padding: $size-s 0
        box-sizing: border-box

      ::v-deep .skeleton-badge

        &--video
          margin: 0 auto
          height: 350px

      .alternative
        padding: $size-s $size-s

        +mq-m--mf
          padding: $size-m $size-l

        ::v-deep .skeleton-badge
          margin-bottom: $size-s

    &__section
      padding: $size-s

      +mq-m--mf
        padding: $size-m $size-l

      &__title
        color: $color-ink
        font-weight: 500
        font-size: $font-size-heading-4

    &__question
      padding: $size-xs $size-s

      +mq-m--mf
        padding: $size-m $size-l

      ::v-deep img
        max-width: 98% !important
        height: auto !important

      ::v-deep .skeleton-badge
        margin-bottom: $size-s

    &__options
      padding: $size-xs $size-s
      border-bottom: 1px solid $color-ink-lightest
      margin-bottom: $size-l

      +mq-m--mf
        padding: $size-m $size-l

      ::v-deep .skeleton-badge
        margin-bottom: $size-s

    &__resolution
      padding: $size-xs $size-s

      +mq-m--mf
        padding: $size-m $size-l

      ::v-deep h4
        font-size: $font-size-m
        font-weight: $font-weight-bold
        margin-bottom: $size-xs

      ::v-deep p
        margin-bottom: $size-s
        font-size: $font-size-s !important

    ::v-deep .item-alternative
      border: none
      box-shadow: none
      cursor: default

      .option__letter
        width: $size-m
        height: $size-m
        line-height: $size-m

      &.answer
        .option__letter
          background: $color-success-lightest
          color: $color-success-darker
          border-color: $color-success-lightest

        .item-alternative__body__letter
          background: $color-success-lightest
          color: $color-success-darker
          border-color: $color-success-darker

      &.correct-answer
        background: $color-success-lightest !important
        border: 1px solid $color-success-darker

        .item-alternative__body
          background: $color-success-lightest
          border-radius: $size-xs

          &__letter
            background: $color-success-lightest
            color: $color-success-darker
            border-color: $color-success-darker

      &.wrong-answer
        background: $color-danger-lightest

        .item-alternative__body
          background: $color-danger-lightest
          border: 1px solid $color-danger-darker
          border-radius: $size-xs

          &__letter
            background: $color-danger-lightest
            color: $color-danger-darker
            border-color: $color-danger-darker

</style>
