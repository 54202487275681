<template>
  <InfoCard
    class="score-card"
    :error="error"
    :justify="justify"
    :padding="padding"
    :title="title"
    @refresh="$emit('refresh')"
  >
    <!-- Body -->
    <template v-if="hasBodyInfo">
      <Info
        v-for="(item, index) in bodyInfo"
        :key="index"
        :icon="item.icon"
        :label="item.label"
        :loading="loading"
        :padding="`4px 4px 4px ${gutterBody}px`"
        :size="item.size"
        :style="{
          marginLeft: `${gutterBody}px`,
          borderLeftWidth: dividerEnabled ? '1px' : '0',
        }"
        :text="item.text"
      />
    </template>

    <Info
      v-else-if="bodyInfo !== null"
      :icon="bodyInfo.icon"
      :label="bodyInfo.label"
      :loading="loading"
      :size="bodyInfo.size"
      :text="bodyInfo.text"
    />

    <!-- Custom Footer -->
    <template v-if="$slots['custom-footer']">
      <slot
        slot="footer"
        name="custom-footer"
      />
    </template>

    <!-- Footer  -->
    <template v-else>
      <template v-if="hasFooterInfo">
        <Info
          v-for="(item, index) in footerInfo"
          :key="index"
          slot="footer"
          :icon="item.icon"
          :label="item.label"
          :loading="loading"
          :size="item.size"
          :text="item.text"
        />
      </template>

      <Info
        v-else-if="footerInfo !== null"
        slot="footer"
        :icon="footerInfo.icon"
        :label="footerInfo.label"
        :loading="loading"
        :padding="`4px 4px 4px ${gutterFooter}px`"
        :size="footerInfo.size"
        :style="{ marginLeft: `${gutter.footer / 2}px` }"
        :text="footerInfo.text"
      />
    </template>
  </InfoCard>
</template>

<script>
import Info from '@/components/Info'
import InfoCard from '@/components/InfoCard'

export default {
  name: 'ScoreCard',

  components: {
    Info,
    InfoCard,
  },

  props: {
    loading: Boolean,

    title: {
      type: String,
    },

    bodyInfo: {
      type: [Object, Array],
      default: () => [],
    },

    footerInfo: {
      type: [Object, Array],
      default: () => [],
    },

    gutter: {
      type: Object,
      default: () => ({
        body: 64,
        footer: 40,
      }),
    },

    dividerEnabled: Boolean,

    padding: {
      type: Object,
      default: () => {},
    },

    justify: {
      type: [Object, String],
      default: () => {},
    },

    error: Boolean,
  },

  computed: {
    gutterBody() {
      const halfSize = 2

      return this.gutter.body / halfSize
    },

    gutterFooter() {
      const halfSize = 2

      return this.gutter.footer / halfSize
    },

    hasBodyInfo() {
      return Array.isArray(this.bodyInfo)
        && this.bodyInfo.length > 0
        && this.bodyInfo !== null
    },

    hasFooterInfo() {
      return Array.isArray(this.footerInfo)
        && this.footerInfo.length > 0
        && this.footerInfo !== null
    },
  },
}
</script>

<style lang="sass">
$font-size-custom-info-text: 24px
.score-card
  .info-2.--x-large:not(:first-child)
    border-left: 1px solid transparentize($color-ink-lighter, .5)

  .info-2:first-child
    margin-left: 0 !important
    padding-left: 0 !important

  .info__label
    font-size: $font-size-m

  .info-card__footer
    .info__text
      font-size: $font-size-custom-info-text
    .info__label
      font-size: $font-size-s

</style>
