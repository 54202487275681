import { mapGetters } from 'vuex'
import urls from '@/utils/urls'

export const STORAGE_KEY = `${urls.STORAGE_PREFIX}/syllabus-tour/redirect-tour`
const REDIRECT_TO_SYLLABUS_TOUR_VERSION = '1.0.2'

export default {

  computed: {
    ...mapGetters(['user']),
    userId() {
      return this.user?.id || null
    },
  },

  data() {
    return {
      tourCanBeVisible: false,
    }
  },

  mounted() {
    this.setupConfig()
    this.setTourCanBeVisible()
  },

  methods: {
    setupConfig() {
      if (!localStorage.getItem(STORAGE_KEY)) {
        const DEFAULT_TOUR_STORAGE = { users: {} }

        localStorage.setItem(STORAGE_KEY, JSON.stringify(DEFAULT_TOUR_STORAGE))
      }
    },

    saveViewedTour() {
      let stored = JSON.parse(localStorage.getItem(STORAGE_KEY))

      if (typeof stored === 'string') {
        stored = JSON.parse(stored)
      }

      if (!stored.users[this.user.id]) {
        stored.users[this.user.id] = {}
      }

      stored.users[this.user.id].viewRedirectSyllabusTour = REDIRECT_TO_SYLLABUS_TOUR_VERSION

      localStorage.setItem(STORAGE_KEY, JSON.stringify(stored))
    },

    setTourCanBeVisible(value) {
      if (value) {
        this.tourCanBeVisible = value

        return
      }

      let stored = JSON.parse(localStorage.getItem(STORAGE_KEY))

      if (typeof stored === 'string') {
        stored = JSON.parse(stored)
      }

      const user = stored.users[this.user.id] || {}

      this.tourCanBeVisible = user.viewRedirectSyllabusTour !== REDIRECT_TO_SYLLABUS_TOUR_VERSION
    },
  },
}
