<template>
  <div
    class="result-exam-card"
    :tabindex="content.examId"
    @click="selectExam()"
  >
    <box
      :class="isSelected ? 'selected-box' : ''"
      elevation="2"
      elevation-hover="3"
      padding="16px"
    >
      <section class="result-exam-card__header">
        <div class="result-exam-card__header--exam">
          {{ examName }}
        </div>
        <div class="result-exam-card__header--icon">
          <SBadge
            v-if="isSelected"
            icon="check"
            variation="primary"
          />
        </div>
      </section>
      <section
        v-if="isOlympics"
        class="result-exam-card__body"
      >
        <div class="result-exam-card__body--average">
          <h5 class="title-text">
            {{ nationalAverage }}
          </h5>
          <p v-t="'pages.finalResult.examCard.average'" />
        </div>
      </section>
      <section
        v-else
        class="result-exam-card__body"
      >
        <div class="result-exam-card__body--position">
          <h5 class="title-text">
            {{ position }}
          </h5>
          <p>{{ participants }}</p>
        </div>
        <div class="result-exam-card__body--average">
          <h5 class="title-text">
            {{ nationalAverage }}
          </h5>
          <p v-t="'pages.finalResult.examCard.average'" />
        </div>
      </section>
      <section
        v-if="isOlympics"
        class="result-exam-card__olympics-footer"
      >
        <div class="result-exam-card__olympics-footer__info">
          <p class="result-exam-card__olympics-footer__info__ranking-number">
            {{ positionInSchool }}
          </p>
          <p class="result-exam-card__olympics-footer__info__ranking-label">
            Escola
          </p>
        </div>
        <div class="result-exam-card__olympics-footer__info">
          <p class="result-exam-card__olympics-footer__info__ranking-number">
            {{ position }}
          </p>
          <p class="result-exam-card__olympics-footer__info__ranking-label">
            Nacional
          </p>
        </div>
      </section>
      <section
        v-else
        class="result-exam-card__footer"
      >
        <div class="result-exam-card__footer--info">
          <div>
            {{ totalParticipants }}
          </div>
          <div>
            {{ percentualParticipants }}%
          </div>
        </div>
        <ProgressBar
          :background="$tokens.color_ink_lightest"
          color="#0467DB"
          height="7px"
          :total="100"
          :value="percentualParticipants"
        />
      </section>
    </box>
  </div>
</template>

<script>
export default {
  name: 'ResultExamCard',
  props: {
    content: {
      type: Object,
      default: () => {},
    },
    selectedCard: {
      type: Number,
      default: 0,
    },
    isOlympics: {
      type: Boolean,
      requird: false,
    },
  },
  computed: {
    isSelected() {
      return this.selectedCard === this.content.examId
    },
    examName() {
      if (this.content && this.content.name) {
        return this.content.name
      }

      return ''
    },
    positionInSchool() {
      if (this.content && this.content.classification) {
        const { classification } = this.content

        return this.$t(
          'pages.finalResult.examCard.position',
          [classification],
        )
      }

      return '--'
    },
    position() {
      if (this.content && this.content.nationalClassification) {
        const { nationalClassification } = this.content

        return this.$t(
          'pages.finalResult.examCard.position',
          [nationalClassification],
        )
      }

      return '--'
    },
    participants() {
      if (this.content && this.content.numberOfGlobalParticipants) {
        const { numberOfGlobalParticipants } = this.content

        return this.$t(
          'pages.finalResult.examCard.participants',
          [numberOfGlobalParticipants],
        )
      }

      return '--'
    },
    nationalAverage() {
      const decimalPlaces = 2

      if (this.content && this.content.irt) {
        const { irt } = this.content

        return irt.toFixed(decimalPlaces)
      }

      return '--'
    },
    totalParticipants() {
      if (this.content && this.content.numberOfParticipants) {
        const { numberOfParticipants, numberOfSubscribers } = this.content

        return this.$t(
          'pages.finalResult.examCard.totalParticipants',
          [numberOfParticipants, numberOfSubscribers],
        )
      }

      return '--'
    },
    percentualParticipants() {
      if (this.content && this.content.percentageParticipation) {
        const { percentageParticipation } = this.content

        return percentageParticipation
      }

      return 0
    },
  },
  methods: {
    selectExam() {
      this.$emit('select-exam', this.content)
    },
  },
}
</script>

<style lang="sass" scoped>
$custom-border-color: #0467DB

.selected-box
  border-style: solid
  border-color: $custom-border-color
  border-width: $size-xxs
  transition: 0s

.title-text
  color: $color-ink

.result-exam-card
  outline: none
  cursor: pointer
  margin-right: 12px
  font-size: 13px
  color: $color-ink-light

  +mq-xs--mf
    width: 240px
    margin-left: $size-xl

  +mq-m--mf
    width: 336px
    margin-left: 0

  +mq-l--mf
    width: 100%

  &__header
    margin-bottom: $size-m
    display: grid
    grid-template-columns: 1fr auto

    &--exam
      margin-bottom: 13px

    &--icon
      ::v-deep .sas-badge
        background: $color-primary
        color: $color-white

        i
          mix-blend-mode: normal
          opacity: 1

  &__body
    display: grid
    grid-template-columns: 70% 30%
    margin-bottom: $size-l

  &__footer
    &--info
      display: grid
      grid-template-columns: 1fr auto

  &__olympics-footer
    display: flex
    align-items: center
    justify-content: space-between

    &__info
      &__ranking-number
        font-weight: $font-weight-medium
        color: $color-ink
        font-size: $font-size-m
</style>
