<template>
  <div class="skeletonLoaderContainer">
    <skeleton-loader
      height="36px"
      width="100%"
    />
    <div class="cardTextContainer">
      <skeleton-loader
        height="16px"
        width="90%"
      />
      <skeleton-loader
        height="16px"
        width="75%"
      />
      <skeleton-loader
        height="16px"
        width="30%"
      />
    </div>

    <div class="cardImage">
      <skeleton-loader
        height="150px"
        width="150px"
      />
    </div>

    <div class="cardFooter">
      <skeleton-loader
        height="36px"
        width="120px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SyllabusRedirectCardSkeleton',
}
</script>

<style lang="scss">
.skeletonLoaderContainer {
  height: 100%;
  position: relative;
}

.cardTextContainer {
  margin: $size-xs 0;

  & > * {
    margin-bottom: 4px;
  }
}

.cardImage {
  @include flex-center;
  margin: $size-s 0;
}

.cardFooter {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>
