<template>
  <Wrapper class="general-result__ranked-wrapper">
    <div class="general-result__ranked">
      <transition-group
        appear
        class="general-result__ranked__grid"
        :class="{ '--complex-layout': hasComplexLayout }"
        name="cards"
        tag="section"
      >
        <RankedGeneralRanking
          :key="1"
          class="general-result__ranked__ranking"
          :class="{ '--complex-layout': hasComplexLayout }"
          :exam-id="examId"
          :is-high-school="isGradedByIrt"
          @update-layout="updateLayout($event)"
        />

        <RankedScoreboard
          :key="2"
          class="general-result__ranked__average"
          :class="{ '--complex-layout': hasComplexLayout }"
          :exam-id="examId"
          :is-high-school="isGradedByIrt"
        />
      </transition-group>

      <div
        :class="[
          'general-result__ranked__position__grid',
          { 'has-syllabus': syllabusCardIsVisible },
        ]"
      >
        <RankedPlacement
          class="general-result__ranked__positioning"
          :exam-id="examId"
          :is-high-school="isGradedByIrt"
          :large-size="false"
        />
        <template v-if="syllabusCardIsVisible">
          <SyllabusRedirectCard
            :assessment-id="assessmentId"
            class="general-result__ranked__banner"
            @retry="getSyllabusInfo(assessmentId)"
          />
        </template>
      </div>

      <section
        v-if="isCategoryRankingVisible"
        class="general-result__ranked__category-ranking-label"
      >
        <h3>
          Classificação por Disciplina
          <Info
            v-if="mq_m"
            :color="$tokens.color_ink_lighter"
            icon="info"
            size="x-small"
            :text="
              `<small>
                ${$t('components.generalRanked.notUsed', categoriesNotPresent)}
              </small>`
            "
          />
        </h3>

        <RankedCategoryRanking
          :assessment-id="assessmentId"
          class="general-result__ranked__subjects"
          :exam-id="examId"
          :is-high-school="isGradedByIrt"
        />
      </section>
    </div>

    <StudyGuideModal
      v-if="isModalVisible"
      key="studyGuideModal"
      @close="isModalVisible = false"
    />
  </Wrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import SyllabusRedirectCard from '@/components/Syllabus/SyllabusRedirectCard'
import RankedScoreboard from '@/components/RankedScoreboard'
import RankedGeneralRanking from '@/components/RankedGeneralRanking'
import RankedPlacement from '@/components/RankedPlacement'
import RankedCategoryRanking from '@/components/RankedCategoryRanking'
import StudyGuideModal from '@/components/StudyGuideModal'
import Info from '@/components/Info'

import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'ExamResultGeneralRanked',

  components: {
    Info,
    RankedScoreboard,
    StudyGuideModal,
    RankedGeneralRanking,
    RankedPlacement,
    RankedCategoryRanking,
    SyllabusRedirectCard,
  },

  mixins: [mediaQueries],

  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },

    examId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      hasComplexLayout: true,
      isModalVisible: false,
      categoriesNotPresent: 'Filosofia e Arte',
    }
  },

  computed: {
    ...mapGetters([
      'isGradedByIrt',
      'assessment',
      'user',
      'exam',
    ]),
    ...mapState({
      syllabusInfo: (state) => state.syllabus,
    }),

    syllabusCardIsVisible() {
      return this.syllabusInfo.data.hasSyllabus || this.syllabusInfo.error
    },

    isCategoryRankingVisible() {
      return this.assessment.typeSlug === 'sistematica'
    },
  },

  methods: {
    ...mapActions(['getSyllabusInfo']),
    updateLayout(hasComplexLayout) {
      this.hasComplexLayout = hasComplexLayout
    },
    openSmallStudyGuide() {
      this.isModalVisible = true

      this.$trackEvent({
        category: this.$track.category.oldSyllabus,
        action: this.$track.action.syllabus.studentClickedOnBanner,
      })
    },
  },
}
</script>

<style lang="sass">

.general-result__ranked
  margin-top: 40px
  margin-bottom: 40px

  // Remove wrapper margins
  &-wrapper
    +mq(425px)
      overflow-x: hidden
      max-width: 100%
      .box
        border-radius: 0 !important

  section > h3
    margin-bottom: 24px

    +mq(425px)
      margin-left: 16px

  &__grid
    margin-bottom: 24px
    display: grid
    grid-template-areas: "ranking ranking average"
    gap: 32px 24px

    +mq-l
      grid-gap: 24px 16px
      margin-bottom: 40px

    +mq-m
      grid-template-areas: "ranking ranking ranking" "average average average"

    &.--complex-layout
      grid-template-areas: "ranking ranking ranking" "average average average"

  &__position__grid
    display: grid
    gap: 32px 24px
    grid-template-areas: "positioning positioning"
    grid-template-columns: 1fr 1fr

    &.has-syllabus
      grid-template-areas: "positioning banner"
      grid-template-columns: 1fr 1fr

      +mq-m
        grid-template-areas: "positioning" "banner"
        grid-template-columns: 100%

  .info-card__footer
    .info-2 ~ .info-2
      +mq-s
        margin-left: 24px

  .info-card
    &__header, &__body, &__footer
      +mq-s
        padding: 16px

    .info-card__body .info__icon
      +mq(425px)
        display: none

    // Scores responsive type scale
    h1
      +mq-m
        font-size: 32px
      +mq-s
        font-size: 28px
      +mq-xs
        font-size: 24px

    h3
      +mq-m
        font-size: 18px
      +mq-s
        font-size: 16px

    h4
      +mq-m
        font-size: 16px
      +mq-s
        font-size: 14px
        font-weight: 500

  // Remove info margins on small devices
  .info-card__body > .info-2:not(:first-child)
    +mq-s
      border: none !important
      margin-left: 32px !important
      padding-left: 4px !important

  &__ranking
    grid-area: ranking

  &__average
    grid-area: average

  &__positioning
    grid-area: positioning

    +mq-l
      max-width: 100%

  &__banner
    grid-area: banner

  &__ranking.--complex-layout, &__average.--complex-layout

    .info-card__body
      +mq-l
        display: grid
        grid-template-columns: max-content max-content
        gap: 48px 80px
        justify-content: start !important
      +mq-s
        gap: 32px 48px
      +mq(425px)
        gap: 32px

      .info-2
        +mq-l
          border: none !important
          margin-left: 0 !important
          padding-left: 0 !important

  &__positioning
    .info-card__body
      padding: 0 24px 8px 24px

      +mq-m
        padding: 16px

  // Subjects Data Table
  &__subjects
    +mq-s
      overflow-x: scroll

      .data-table td:first-child,
      .data-table th:first-child
          padding-left: 16px

      .data-table th
        padding: 8px 8px

      .data-table td
        padding: 16px 8px

  &__category-ranking-label
    margin-top: $size-m
</style>
