<template>
  <SasModal
    class="study-guide-modal"
    @close="$emit('close')"
  >
    <div
      slot="header"
      class="study-guide-modal__header"
    >
      <h3>Plano de Estudos de {{ isEnem ? assessment.name : exam.name }}</h3>
    </div>

    <div
      slot="body"
      class="study-guide-modal__body"
    >
      <div
        v-if="loading"
        key="loading"
        class="study-guide-modal__body--loading"
      >
        <Spinner />
      </div>

      <div
        v-for="(category, index) in categoriesStudyGuide"
        v-else
        :key="index"
        class="study-guide-modal__category"
      >
        <s-button
          :class="[
            'study-guide-modal__category-title',
            { '--enem': isEnem },
          ]"
          :icon-left="buttonIcon(index)"
          variation="tertiary"
          @click="toggleCategory(index)"
        >
          {{ category.composedName }}
        </s-button>

        <s-button
          v-if="isEnem"
          class="study-guide-modal__download"
          icon="download"
          variation="tertiary"
          @click="downloadStudyGuideFileByArea(category)"
        />

        <STable v-if="selectedCategories[index]">
          <tr slot="header">
            <th>Habilidades que você precisa desenvolver</th>
            <th v-if="category.skills[0].content && !isSmallScreen">
              Práticas de Linguagem
            </th>
          </tr>

          <tr
            v-for="(skill, index) in category.skills"
            :key="index"
          >
            <td :title="skill.name">
              <b>({{ skill.code }})</b> {{ skill.name | truncate(128) }}

              <small
                v-if="skill.content && isSmallScreen"
                :title="skill.content"
              >
                <br><br>
                <b>{{ skill.content | truncate(35) }}</b>
              </small>
            </td>

            <td
              v-if="skill.content && !isSmallScreen"
              :title="skill.content"
            >
              {{ skill.content | truncate(35) }}
            </td>
          </tr>
        </STable>
      </div>
    </div>

    <div
      slot="footer"
      class="study-guide-modal__footer"
    >
      <h4>
        {{
          isEnem
            ? 'Baixe as questões de cada competência e bons estudos!'
            : 'Baixar questões do Plano de Estudos?'
        }}
      </h4>

      <div class="study-guide-modal__footer__actions">
        <s-button
          :size="isSmallScreen ? 'medium' : 'large'"
          variation="tertiary"
          @click="$emit('close')"
        >
          {{ isEnem ? 'Fechar' : 'Agora não' }}
        </s-button>

        <s-button
          v-if="!isEnem"
          icon-left="download"
          :loading="loadingDownload"
          :size="isSmallScreen ? 'medium' : 'large'"
          @click="downloadStudyGuideFile()"
        >
          Baixar
        </s-button>
      </div>
    </div>
  </SasModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import STable from '@/components/STable'
import SasModal from '@/components/global/SasModal'

export default {
  name: 'StudyGuideModal',
  components: {
    STable,
    SasModal,
  },

  data() {
    return {
      selectedCategories: [],
      categoriesStudyGuide: [],
      loading: false,
      loadingDownload: false,
    }
  },

  computed: {
    ...mapGetters([
      'assessment',
      'exam',
    ]),
    isSmallScreen() {
      return this.$mq === 'x_small' || this.$mq === 'small'
    },
    isEnem() {
      return this.assessment.typeSlug.startsWith('enem')
    },
    buttonIcon() {
      return (index) => (
        this.selectedCategories[index]
          ? 'chevron-down'
          : 'chevron-right'
      )
    },
  },

  created() {
    this.load()
  },

  methods: {
    ...mapActions(['getStudyGuide', 'downloadStudyGuideQuestions']),

    async load() {
      this.loading = true

      try {
        const { assessmentId, examId } = this.$route.params

        this.categoriesStudyGuide = await this.getStudyGuide({
          assessmentId: this.isEnem ? assessmentId : null,
          examId: this.isEnem ? null : examId,
        })

        this.selectedCategories = this.categoriesStudyGuide.map(() => false)
      } catch (error) {
        this.$toasted.global.error({
          message: 'Ocorreu um erro tentar carregar o plano de estudos',
          errorCode: error.response ? error.response.status : '',
        })
      } finally {
        this.loading = false
      }
    },

    async downloadStudyGuideFile() {
      this.loadingDownload = true

      const guids = this.getGuidsFromStudyGuide()
      const title = `Plano de Estudos · ${this.assessment.name} · ${this.exam.name}`

      try {
        const data = {
          title,
          guids,
        }
        const response = await this.downloadStudyGuideQuestions(data)
        const blob = this.getStudyGuideBlob(response.data)

        if (this.isIos()) {
          this.generateStudyGuideFileForIos(blob)
        } else {
          this.generateStudyGuideFile(blob)
        }
      } finally {
        this.loadingDownload = false
      }
    },

    async downloadStudyGuideFileByArea(category) {
      this.loadingDownload = true
      const guids = this.getGuidsFromStudyGuideByArea(category)
      const title = `Plano de Estudos · ${this.assessment.name} · ${this.exam.name}`
      const { examType } = category
      try {
        const data = {
          title,
          guids,
          examType,
        }

        const response = await this.downloadStudyGuideQuestions(data)
        const blob = this.getStudyGuideBlob(response.data)
        if (this.isIos()) {
          this.generateStudyGuideFileForIos(blob)
        } else {
          this.generateStudyGuideFile(blob)
        }
      } finally {
        this.loadingDownload = false

        this.$trackEvent({
          category: this.$track.category.oldSyllabus,
          action: this.$track.action.syllabus.studentClickedOnDownload,
          label: category.examType,
        })
      }
    },

    getGuidsFromStudyGuide() {
      const skills = []
      this.categoriesStudyGuide
        .forEach((categorie) => skills.push(...categorie.skills))
      const guids = []
      skills.forEach((skill) => guids.push(...skill.relatedQuestions))

      return guids
    },

    getGuidsFromStudyGuideByArea(category) {
      let codes = []
      category.skills.forEach((skill) => {
        const { relatedQuestions } = skill
        codes = [...codes, ...relatedQuestions]
      })

      return codes
    },

    getStudyGuideBlob(data) {
      return new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      })
    },

    isIos() {
      return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    },

    generateStudyGuideFileForIos(blob) {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        window.location.href = fileReader.result
      }
      fileReader.filename = 'Plano de Estudos.docx'
      fileReader.readAsDataURL(blob)
    },

    generateStudyGuideFile(blob) {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Plano de Estudos.docx')
      document.body.appendChild(link)
      link.click()
    },

    showDownloadErrorMessage(error) {
      const message = 'Ocorreu um erro tentar realizar download.'
      this.$toasted.global.error({
        message,
        errorCode: error.response ? error.response.status : '',
      })
    },

    toggleCategory(index) {
      this.selectedCategories[index] = !this.selectedCategories[index]
      this.selectedCategories = { ...this.selectedCategories }
    },
  },
}
</script>

<style lang="sass" scoped>
  .study-guide-modal

    ::v-deep .modal
      padding: 0
      box-sizing: border-box
      overflow: hidden
      width: 960px

    ::v-deep .modal__title,
    ::v-deep .modal__footer
      margin: 0
      background: white

    &__body,
    &__footer,
    &__header > h3
      box-sizing: border-box
      padding: 32px 48px

      +mq-m
        padding: 24px 32px

      +mq-s
        padding: 16px

    &__header
      padding: 0
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14)

      h3
        box-sizing: border-box
        padding-bottom: 16px

        +mq-s
          margin-right: 32px

        +mq-xs
          font-size: 20px

    &__body
      height: 60vh
      overflow-y: auto

      &--loading
        display: flex
        align-items: center
        justify-content: center
        height: 100%

      +mq-m
        height: calc(100vh - 160px)

      +mq-s
        padding-bottom: 104px

    &__category
      padding: 0 0 $size-l 0

      +mq-m
        padding: 0 0 $size-s 0

      &-title
        text-align: start
        margin-bottom: $size-s
        min-height: $size-xl
        height: auto
        color: $color-ink

        ::v-deep i.feather
          margin-right: $size-s
          overflow: unset

        &.--enem
          max-width: 95%

          +mq-s
            max-width: 85%

    &__footer
      padding-top: 24px
      padding-bottom: 24px
      width: 100%
      justify-content: space-between

      +mq-s
        padding: 0
        padding-top: 8px
        flex-direction: column
        text-align: center

        h4
          font-size: 14px

      &, &__actions
        display: flex
        align-items: center

      &__actions

        ::v-deep .sas-button ~ .sas-button
          margin-left: $size-s

        +mq-s
          padding: $size-s $size-xs $size-xs $size-xs

    &__table-title
      display: none
      margin-top: $size-xs
      margin-bottom: $size-s

      +mq-s
        display: block

</style>
