<template>
  <s-button
    :active="active"
    class="sas-page-button"
    :disabled="disabled"
    :icon="icon"
    :loading="loading"
    :title="title"
    variation="tertiary"
    @click="pageChange()"
  >
    <span v-if="text">
      {{ text }}
    </span>
  </s-button>
</template>

<script>
export default {
  name: 'SPageButton',
  props: {
    page: {
      type: [
        Number,
        String,
      ],
      required: true,
    },
    active: Boolean,
    disabled: Boolean,
    text: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    loading: Boolean,
  },
  methods: {
    pageChange() {
      if (
        this.page === '...'
        || this.disabled
        || this.active
      ) {
        return
      }

      this.$emit('page-change', this.page)
    },
  },
}
</script>
