<template>
  <wrapper>
    <section class="olympics-exam__card-comparative">
      <div v-if="loadingOlympicSummary">
        <skeleton-loader
          :height="skeletonSize"
          width="100%"
        />
      </div>
      <div
        v-for="exam in olympicSummaryExams"
        v-else
        :key="exam.examId"
      >
        <ResultExamCard
          :content="exam"
          is-olympics
          :selected-card="selectedCard"
          @select-exam="selectExam($event)"
        />
      </div>
    </section>
    <EmptyState
      v-if="emptyExam && !loadingOlympicSummary"
      class="olympics-exam__empty"
      :description="$t('pages.finalResult.emptyState.description')"
      :image="emptyImageSrc"
      :title="$t('pages.finalResult.emptyState.title')"
    />
    <div
      v-else-if="!emptyExam"
      class="olympics-exam__content"
    >
      <QuestionsDrawer v-if="questionDrawerVisible" />
      <QuestionDetailsDrawer v-if="itemDetailsVisible" />
      <AveragesPerformance
        class="average"
        :current-performance-data="currentPerformanceData"
      />
      <QuestionBanner class="banner" />
      <RankedPlacement
        class="ranking"
        is-high-school
        :large-size="false"
      />
    </div>
  </wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AveragesPerformance from '@/components/AveragesPerformance'
import QuestionBanner from '@/components/QuestionBanner'
import QuestionsDrawer from '@/components/QuestionsDrawer'
import QuestionDetailsDrawer from '@/components/QuestionDetailsDrawer'
import RankedPlacement from '@/components/RankedPlacement'
import ResultExamCard from '@/components/ResultExamCard'
import EmptyState from '@/components/EmptyState'

export default {
  name: 'ExamResultOlympics',
  components: {
    AveragesPerformance,
    QuestionBanner,
    QuestionsDrawer,
    QuestionDetailsDrawer,
    RankedPlacement,
    ResultExamCard,
    EmptyState,
  },
  data() {
    return {
      selectedCard: 0,
      bodyInfo: [
        {
          type: 'placing',
          label: this.$t('pages.finalResult.rankingCard.placing'),
          size: 'x-large',
          text: '--',
          icon: {
            name: 'bar-chart-2',
            color: '#4D8AF0',
          },
        },
        {
          type: 'schools',
          label: this.$t('pages.finalResult.rankingCard.participatingSchools'),
          size: 'large',
          text: '--',
        },
      ],
      footerInfo: [
        {
          type: 'footer',
          label: this.$t('pages.finalResult.rankingCard.participantsAmount'),
          size: 'large',
          text: '--',
        },
      ],
      currentPerformanceData: {},
      emptyImageSrc: {
        url: import('../assets/examSearch.svg'),
      },
    }
  },
  computed: {
    ...mapGetters([
      'assessment',
      'exam',
      'questionDrawerVisible',
      'selectedQuestion',
      'olympicSummaryExams',
      'loadingOlympicSummary',
    ]),
    itemDetailsVisible() {
      return Object.keys(this.selectedQuestion).length > 0
    },
    skeletonSize() {
      if (this.$mq === 'medium') {
        return '270px'
      }

      return '174px'
    },
    emptyExam() {
      const { nationalClassification } = this.olympicSummaryExams
        .find(({ examId }) => examId === this.selectedCard)

      return !nationalClassification
    },
  },
  watch: {
    '$route.params.examId': function () {
      this.getCurrentPerformanceData()
    },
  },
  created() {
    const { examId } = this.$route.params
    this.selectedCard = examId
    this.loadExams()
  },
  methods: {
    ...mapActions([
      'toggleQuestionDrawer',
      'getOlympicSummary',
      'getExam',
    ]),
    async loadExams() {
      const { params } = this.$route

      try {
        await this.getOlympicSummary(params)
        this.getCurrentPerformanceData()
      } catch (error) {
        this.$toasted.global.error({
          message: this.$t('pages.finalResult.error'),
        })
      } finally {
        this.updateSelectedCard()
        const { examId } = this.$route.params
        this.fetchExam(examId, false)
      }
    },
    updateSelectedCard() {
      const { examId } = this.$route.params
      this.selectedCard = parseInt(examId, 10)
    },
    async fetchExam(exam, updateRoute = true) {
      this.loading = true
      try {
        await this.getExam(exam)
      } catch (error) {
        this.$toasted.global.error({
          message: this.$t('pages.finalResult.error'),
        })
      } finally {
        if (updateRoute) {
          this.$router.replace({
            name: 'examResultGeneral',
            params: {
              ...this.$route.params,
              examId: exam.examId,
            },
          })
        }
        this.loading = false
      }
    },

    async selectExam(exam) {
      const { examId, nationalClassification } = exam
      const { assessmentId } = this.$route.params
      this.selectedCard = examId

      if (nationalClassification) {
        try {
          await this.fetchExam({
            assessmentId,
            examId,
          })
        } catch (error) {
          this.$toasted.global.error({
            message: this.$t('pages.finalResult.error'),
          })
        } finally {
          this.updateSelectedCard()
        }
      }
    },

    getCurrentPerformanceData() {
      const currentPerformance = this.olympicSummaryExams
        .find(({ examId }) => examId === Number(this.$route.params.examId))

      this.currentPerformanceData = {
        examType: currentPerformance.name,
        scores: [
          {
            name: 'IRT',
            score: currentPerformance.irt,
            globalScore: currentPerformance.averageIrtScore,
          },
          {
            name: 'CLASSIC',
            score: currentPerformance.raw,
            globalScore: currentPerformance.averageRawScore,
          },
        ],
        footers: [
          {
            order: 0,
            value: currentPerformance.classification,
            type: 'SCHOOL_RANKING',
          },
          {
            order: 1,
            value: currentPerformance.numberOfParticipants,
            type: 'SCHOOL_STUDENT_AMOUNT',
          },
          {
            order: 2,
            value: currentPerformance.nationalClassification,
            type: 'GLOBAL_RANKING',
          },
          {
            order: 3,
            value: currentPerformance.numberOfGlobalParticipants,
            type: 'GLOBAL_STUDENT_AMOUNT',
          },
        ],
      }
    },
  },
}
</script>

<style lang="sass" scoped>

.olympics-exam

  &__card-comparative
    padding-top: $size-l
    display: grid
    grid-gap: 12px

    +mq-xs--mf
      grid-template-columns: 1fr

    +mq-s--mf
      grid-template-columns: repeat(2, 1fr)

    +mq-l--mf
      grid-template-columns: repeat(4, 1fr)

  &__content
    padding-top: $size-l
    display: grid
    padding-bottom: $size-xl
    gap: $size-m
    grid-template-areas: "average" "ranking" "banner"
    grid-template-columns: 100%

    +mq-m--mf
      grid-template-areas: "average average" "ranking banner"
      grid-template-columns: 50% 50%

    +mq-m--mf
      max-width: calc(100% - 80px)

    +mq-l--mf
      max-width: 1032px

    .average
      grid-area: average

    .ranking
      grid-area: ranking

      &.info-card
        box-shadow: 0px 1px 2px rgba(52, 60, 88, 0.4)

      ::v-deep .info-card__header

        .info-card__title
          font-weight: 500
          font-size: $font-size-heading-4
          color: $color-ink

      ::v-deep .info-card__body
        padding: $size-l !important

      ::v-deep .vertical-bar-chart

        &__bars
          grid-template-columns: repeat(5, 28px) !important

          +mq-s--mf
            grid-template-columns: repeat(5, 48px) !important

    .banner
      grid-area: banner

      ::v-deep .questions-banner__box
        background-size: 100% !important

        &__img
          width: 40%

          +mq-m--mf
            width: 100%
</style>
