<template>
  <div class="sas-table-container">
    <slot name="list-info" />
    <Box
      class="box-sas-table"
      :elevation="elevation"
      padding="0"
    >
      <div
        v-if="loading"
        class="container-loading"
      >
        <Box
          :elevation="10"
          padding="10px"
        >
          <spinner size="large" />
          {{ $t('components.sPaginatedTable.loading') }}
        </Box>
      </div>
      <table
        class="sas-table"
        :class="{ 'always-show-head': alwaysShowHeader }"
      >
        <thead
          v-if="fields.length"
          class="sas-table__header"
        >
          <tr>
            <th v-if="showIndex" />
            <!-- Checkbox -->
            <th
              v-if="selectable"
              class="data-table__header__checkbox"
            >
              <input
                :checked="allSelected"
                type="checkbox"
                @click="$emit('select-all')"
              >
            </th>
            <th
              v-for="(field, index) in fields"
              :key="index"
              class="data-table__column"
              :class="[
                { '--sortable': field.sortable },
                field.responsive,
                field.customClass,
              ]"
              :style="field.headerStyle"
              @click="field.sortable ? updateSort(field.value) : null"
            >
              <div
                class="sas-table__header__field"
                :style="{
                  'justify-content': field.textAlign || '',
                }"
              >
                {{ field.text }}
                <icon
                  v-if="field.value === sortField && field.sortable"
                  :class="[
                    'sas-table__header__field__icon',
                    `--${sortDirection}`,
                  ]"
                  size="16"
                  type="arrow-down"
                />
                <slot
                  v-if="field.additionalInfo"
                  :name="`${field.value}-additional-info`"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr
            v-for="index in skeletonLines"
            :key="index"
          >
            <td v-if="selectable">
              <input type="checkbox">
            </td>
            <td
              v-for="(field, indexTd) in fields"
              :key="indexTd"
              class="data-table__column"
              :class="field.responsive"
              :style="{ 'text-align': field.textAlign || '' }"
            >
              <skeleton-loader
                height="16px"
                width="100%"
              />
            </td>
          </tr>
        </tbody>
        <tbody
          v-else
          class="sas-table__body"
        >
          <tr
            v-for="(row, index) in content"
            :key="index"
            :class="[
              `row-${getRowClass(row)}`,
              {
                '--selected': selectedRows
                  .map(m => m[identifier])
                  .includes(row[identifier]),
                'additional-info': hasAdditionalFields && index === 0,
              },
            ]"
            :tabindex="index"
            @click="$emit('click-row', row)"
            @keydown.enter="$emit('click-row', row)"
          >
            <td
              v-if="showIndex"
              class="data-table__body__index"
            >
              {{ index + 1 }}
            </td>
            <!-- Checkbox -->
            <td
              v-if="selectable"
              class="data-table__body__checkbox"
            >
              <input
                :checked="selectedRows
                  .map(m => m[identifier])
                  .includes(row[identifier])
                "
                type="checkbox"
                @click.stop="$emit('select-row', row)"
              >
            </td>

            <td
              v-for="(field, indexTd) in fields"
              :id="`${field.value}-${index}`"
              :key="indexTd"
              class="data-table__column"
              :class="[
                field.responsive,
                field.customClass,
              ]"
              :style="field.style"
            >
              <template v-if="!$scopedSlots[field.value]">
                <template v-if="formatData">
                  {{ formatData(row[field.value]) }}
                </template>
                <template v-else>
                  <span
                    :title="field.style && field.style.textOverflow
                      ? row[field.value] : ''"
                  >
                    {{ row[field.value] }}
                  </span>
                </template>
              </template>
              <slot
                v-else
                :name="field.value"
                :row="field.index ? {
                  row,
                  index,
                } : row"
              />
            </td>
          </tr>
        </tbody>
        <tfoot
          v-if="footer.length"
          class="sas-table__footer"
        >
          <slot name="footer" />
        </tfoot>
      </table>
    </Box>
    <SPagination
      v-if="paginate"
      class="sas-pagination__container"
      :items-per-page="itemsPerPage"
      :page="page.page"
      :total-items="page.totalItems"
      @page-change="pageChange()"
      @range-change="rangeChange()"
    >
      <template slot-scope="props">
        <slot
          :end="props.end"
          name="pagination"
          :start="props.start"
          :total="props.total"
        />
      </template>
    </SPagination>

    <s-button
      v-if="paginate && loadMore"
      class="show-more-pagination"
      :disabled="disablePagination"
      @click="loadChange()"
    >
      Mostrar mais
    </s-button>
  </div>
</template>

<script>
import SPagination from './Pagination/SPagination'

export default {
  name: 'SPaginatedTable',
  components: {
    SPagination,
  },
  props: {
    content: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    footer: {
      type: Array,
      default: () => [],
    },
    defaultSort: {
      type: Object,
      default: () => ({
        field: '',
        direction: 'asc',
      }),
    },
    formatData: {
      type: Function,
      default: () => {},
    },
    fieldToClass: {
      type: String,
      default: null,
    },
    loading: Boolean,
    skeletonLines: {
      type: Number,
      default: 1,
    },
    elevation: {
      type: [String, Number],
      default: 1,
    },
    paginate: Boolean,
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    backendPaged: Boolean,
    selectable: Boolean,
    selectedRows: {
      type: [Array, Object],
      default: () => [],
    },
    identifier: {
      type: String,
      default: 'id',
    },
    allSelected: Boolean,
    page: {
      type: Object,
      default: () => {},
    },
    disablePagination: Boolean,
    alwaysShowHeader: Boolean,
    showIndex: Boolean,
  },
  data() {
    return {
      sortField: this.defaultSort.field,
      sortDirection: this.defaultSort.direction,
      pagination: {
        page: (this.page || {}).page,
        start: (this.page || {}).start,
        end: (this.page || {}).end,
      },
    }
  },
  computed: {
    defaultSortField() {
      return this.defaultSort.field
    },
    defaultSortModifier() {
      return this.defaultSort.direction === 'asc' ? 1 : -1
    },
    defaultSortFunction() {
      return this.fields.find((field) => field.value === this.defaultSort.field)
        .sortFunction
    },
    sortModifier() {
      return this.sortDirection === 'desc' ? -1 : 1
    },
    sortedContent() {
      if (!this.sortField || !this.sortDirection) {
        return this.content
      }

      const sortField = this.fields.find(
        (field) => field.value === this.sortField,
      )

      return [...this.content].sort(
        sortField.sortFunction
          ? (a, b) => {
            let order = sortField
              .sortFunction(a[this.sortField], b[this.sortField])
            order *= this.sortModifier
            if (!this.defaultSort.field || order !== 0) {
              return order
            }

            return this.defaultSortFunction
              ? this.defaultSortFunction(
                a[this.defaultSortField],
                b[this.defaultSortField],
              ) * this.defaultSortModifier
              : this.fallbackSortFunction(
                a[this.defaultSortField],
                b[this.defaultSortField],
              ) * this.defaultSortModifier
          }
          : (a, b) => {
            const order = this.fallbackSortFunction(
              a[this.sortField],
              b[this.sortField],
            ) * this.sortModifier
            if (!this.defaultSort.field || order !== 0) {
              return order
            }

            return this.defaultSortFunction
              ? this.defaultSortFunction(
                a[this.defaultSortField],
                b[this.defaultSortField],
              ) * this.defaultSortModifier
              : this.fallbackSortFunction(
                a[this.defaultSortField],
                b[this.defaultSortField],
              ) * this.defaultSortModifier
          },
      )
    },
    hasAdditionalFields() {
      return this.fields.some((field) => field.additionalInfo)
    },
    slicedContent() {
      return this.sortedContent.slice(
        this.pagination.start,
        this.pagination.end,
      )
    },
    selectedContent() {
      return this.paginate ? this.slicedContent : this.sortedContent
    },
    loadMore() {
      return this.page.numberOfElements < this.page.totalItems
    },
  },
  watch: {
    content() {
      this.pagination = this.page
    },
  },
  methods: {
    updateSort(field) {
      if (field === this.sortField) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      }
      this.$emit('updateSort', {
        field,
        direction: this.sortDirection,
      })
      this.sortField = field
    },
    loadChange() {
      this.$emit('load-change')
    },
    fallbackSortFunction(a, b) {
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }

      return 0
    },
    pageChange(page) {
      if (this.backendPaged) {
        this.$emit('page-change', page)
      }
      this.pagination.page = page
    },
    rangeChange(start, end) {
      this.pagination.start = start
      this.pagination.end = end
    },
    getRowClass(row) {
      let result = ''

      if (row[this.fieldToClass]) {
        result = this.fieldToClass
          ? row[this.fieldToClass]
            .replace('_', '-')
            .toLowerCase()
          : ''
      }

      return result
    },
  },
}
</script>

<style lang="sass" scoped>
$borderStyle: 1px solid transparentize($color-ink-lightest, 0.5)
$color-black-low-opacity : rgba(0, 0, 0, 0.15)
$color-ink-lighter-custom: #b1afaf

.sas-table-container
  .box-sas-table
    position: relative

    .container-loading
      position: absolute
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      background: #0000001f
      z-index: 12

      .sas-box
        box-shadow: 0px 2px 6px 4px $color-ink-lighter-custom
        display: flex
        flex-flow: column
        justify-content: space-between
        min-height: 110px
        align-items: center
        margin-top: 40px

  .sas-table
    font-family: $font-family-primary
    width: 100%
    border-collapse: separate
    border-spacing: 0
    position: relative
    transition: all 200ms ease-in-out
    box-sizing: border-box

    &.always-show-head
      thead
        display: table-header-group

        +mq-l--mf
          display: table-header-group

      .data-table__body__checkbox
          display: table-cell

    thead
      display: none

      +mq-l--mf
        display: table-header-group

      th
        padding: $size-xs

    td,
    th
      border-bottom: $borderStyle

    td
      box-sizing: border-box
      padding: $size-xs
      font-size: $font-size-s
      transition: all $speed-fast ease-in-out

      &.data-table__body__checkbox
        display: none

        +mq-l--mf
          display: table-cell

      &.data-table__body__index
        padding-left: $size-m
        width: 10px

    th
      padding: 16px 8px 16px 16px
      text-align: left

    tr
      transition: all $speed-fast ease-in-out

      &:focus
        outline: 0

      &:last-child
        td
          border-bottom: none

    &__header
      &__field
        height: $size-s
        +flex-center-start

        &__icon
          margin-left: $size-xxs
          transition: all $speed-fast
          flex-shrink: 0

          &.--desc
            transform: scaleY(-1)
    thead,
    tbody,
    tfoot
      tr:last-child
        th
          border-bottom: none

      .data-table__column
        &.xs
          display: table-cell

        &.xs-only
          display: table-cell
          +mq-s--mf
            display: none

        &.sm
          display: none
          +mq-s--mf
            display: table-cell

        &.md
          display: none
          +mq-m--mf
            display: table-cell

        &.lg
          display: none
          +mq-l--mf
            display: table-cell

    thead
      text-align: left

      tr
        &:first-child
          th
            &:first-child
              border-top-left-radius: $size-xs

            &:last-child
              border-top-right-radius: $size-xs

      th
        background: transparentize($color-ink-lightest, 0.75)
        box-sizing: border-box
        color: $color-ink-light
        font-size: 11px
        font-weight: 400
        text-transform: uppercase
        vertical-align: middle
        letter-spacing: 1.3px

        &.--sortable
          cursor: pointer

        small
          font-weight: $font-weight-semi-bold
          user-select: none
    tbody
      tr.additional-info
        td
          padding-top: 25px

    tfoot
      tr:first-child
        td,
        th
          border-top: $borderStyle

    &.--vertical-lines
      td:not(:last-child),
      th:not(:last-child)
        border-right: $borderStyle

  .sas-pagination__container
    display: none

    +mq-l--mf
      display: flex

  .show-more-pagination
    display: flex
    background: $color-white
    border: 1px solid $color-ink-lightest
    box-shadow: $shadow-s $color-black-low-opacity
    border-radius: $border-radius-m
    margin: $size-s auto
    color: $color-ink-light

    &:hover
      color: $color-white
      background: $color-ink-light

    +mq-l--mf
      display: none

</style>
