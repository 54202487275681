import { render, staticRenderFns } from "./SPaginatedTable.vue?vue&type=template&id=43145770&scoped=true&"
import script from "./SPaginatedTable.vue?vue&type=script&lang=js&"
export * from "./SPaginatedTable.vue?vue&type=script&lang=js&"
import style0 from "./SPaginatedTable.vue?vue&type=style&index=0&id=43145770&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43145770",
  null
  
)

export default component.exports