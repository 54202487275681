<template>
  <div>
    <v-tour
      v-if="isVisible"
      class="redirectTour"
      name="syllabusTour"
      :steps="responsiveSteps"
    >
      <template slot-scope="tour">
        <v-step
          class="coachmarkStep"
          :current-step="tour.currentStep"
          :highlight="tour.highlight"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
          :next-step="tour.nextStep"
          :previous-step="tour.previousStep"
          :skip="tour.skip"
          :step="tour.steps[tour.currentStep]"
          :stop="tour.stop"
        >
          <div
            slot="header"
            class="paddingContainer stepHeader"
          >
            <h5>Assuntos de maior dificuldade</h5>
            <SButton
              icon="x"
              variation="tertiary"
              @click="handleDismiss()"
            />
          </div>
          <div
            slot="content"
            class="paddingContainer stepContent"
          >
            <p class="stepDescription">
              {{ tour.steps[tour.currentStep].content }}
            </p>

            <img
              alt="Demonstração de Exercícios Personalizados"
              class="syllabusContentImg"
              :src="contentImage"
            >
          </div>
          <div
            slot="actions"
            class="paddingContainer stepActions"
          >
            <SButton
              class="dismissButton"
              variation="secondary"
              @click="handleDismiss()"
            >
              Dispensar
            </SButton>
            <SButton @click="goToSyllabus()">
              Ir para exercícios personalizados
            </SButton>
          </div>
        </v-step>
      </template>
    </v-tour>
  </div>
</template>

<script>
import tourBanner from '@/assets/redirectSyllabusButton/syllabus-tour.png'
import syllabusSteps from './syllabusSteps'
import mediaQueries from '@/mixins/mediaQueries'
import syllabusStorage from './mixins/syllabusStorage'
import urls from '@/utils/urls'

const CLASSNAME_BODY_TOUR = 'in-tour'
const CLASSNAME_TOUR_ACTIVE_CLASS = 'tour-active'

export default {
  name: 'SyllabusTour',

  mixins: [
    mediaQueries,
    syllabusStorage,
  ],

  data() {
    return {
      contentImage: tourBanner,
      isVisible: true,
    }
  },

  computed: {
    stepsList() {
      return syllabusSteps
    },

    responsiveSteps() {
      return this.stepsList
        .filter(({ visibleOnBreakpoints }) => visibleOnBreakpoints.includes(this.$mq))
    },
  },

  mounted() {
    if (this.tourCanBeVisible) {
      this.$tours.syllabusTour.start()
    }
  },

  methods: {
    onStartTour() {
      document.body.classList.add(CLASSNAME_BODY_TOUR)

      const curStepEl = document.querySelector(this.responsiveSteps[0].target)

      curStepEl.classList.add(CLASSNAME_TOUR_ACTIVE_CLASS)
    },

    onStop() {
      document.body.classList.remove(CLASSNAME_BODY_TOUR)
      document.body.classList.remove('v-tour--active')

      if (document.querySelector(`.${CLASSNAME_TOUR_ACTIVE_CLASS}`)) {
        document
          .querySelector(`.${CLASSNAME_TOUR_ACTIVE_CLASS}`)
          .classList.remove(CLASSNAME_TOUR_ACTIVE_CLASS)
      }

      this.isVisible = false
    },

    goToSyllabus() {
      this.$trackEvent({
        category: this.$track.category.assessmentsInsightsTour,
        action: this.$track.action.assessmentsInsightsTour.goToSyllabus,
      })

      this.saveViewedTour()
      this.onStop()
      window.open(urls.ASSESSMENTS_INSIGHTS_URL)
    },

    handleDismiss() {
      this.$trackEvent({
        category: this.$track.category.assessmentsInsightsTour,
        action: this.$track.action.assessmentsInsightsTour.dismiss,
      })

      this.saveViewedTour()
      this.onStop()
    },
  },

}
</script>

<style lang="scss">
.coachmarkStep.v-step .v-step__arrow {
  display: none;
}

#banner-tour-step.v-tour__target--highlighted {
  box-shadow: 0 0 0 9999px rgba(30, 33, 36, 0.4);
}
</style>

<style lang="scss" scoped>
.paddingContainer {
  padding: 12px 16px;
}

.coachmarkStep.v-step {
  min-width: min(100%, 520px) !important;
  z-index: 1029;
  padding: 0;
  background-color: #FFF;
  color: #1E2124;

  @include mq-l() {
    position: absolute;
    will-change: transform;
    top: 0;
    right: 0;
    width: 100%;
    left: unset;
    height: min-content;
  }
}

.stepHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  padding-bottom: 0;
}

.syllabusContentImg {
  margin-top: 16px;
  max-width: 100%;
}

.stepDescription {
  font-size: 14px;
  font-weight: 400;
}

.stepActions {
  border-top: 1px solid #C9CCCF;
  padding: 12px 16px;
  display: flex;
  justify-content: flex-end;
}

.dismissButton {
  margin-right: 8px;
}
</style>
