<template>
  <ScoreCard
    :body-info="scoreboard.main"
    :error="hasError"
    :footer-info="scoreboard.footer || []"
    :justify="isCompositeScoreboard ? { body: 'space-around' } : ''"
    :loading="loading"
    title="Sua nota"
    @refresh="reload()"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ScoreCard from '@/components/ScoreCard'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'RankedScoreboard',

  components: {
    ScoreCard,
  },

  mixins: [mediaQueries],

  props: {
    isHighSchool: {
      type: Boolean,
      required: true,
    },

    examId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      hasError: false,
      scoreResult: {
        scores: [
          {
            name: '',
            score: '',
          },
        ],
        globalScores: [
          {
            name: '',
            score: '',
            lowestScore: '',
            highestScore: '',
          },
        ],
      },
      basicScoreTypes: ['', 'classic', 'raw'],
      advancedScoreTypes: ['', 'irt', 'standard'],
    }
  },

  computed: {
    ...mapGetters([
      'assessment',
      'isDigitalEnem',
    ]),

    scoreTypes() {
      const { scores } = this.scoreResult

      return scores.map((score) => score.name)
    },

    isCompositeScoreboard() {
      if (this.isDigitalEnem) {
        return false
      }

      return this.scoreTypes.length > 1
    },

    basicScoreType() {
      return this.scoreTypes.find(
        (type) => this.basicScoreTypes.includes(type.toLowerCase()),
      )
    },

    advancedScoreType() {
      return this.scoreTypes.find(
        (type) => this.advancedScoreTypes.includes(type.toLowerCase()),
      )
    },

    isIrt() {
      return this.advancedScoreType === 'irt'
    },

    basicScores() {
      const { scores, globalScores } = this.scoreResult

      return [
        {
          label: 'Nota Bruta',
          text: this.getFormattedScore(scores, this.basicScoreType),
          size: 'x-large',
          icon: {
            name: 'award',
            color: this.$tokens.color_primary,
          },
        },
        {
          label: 'Média Nacional',
          class: 'info-ranking',
          text: this.getFormattedScore(globalScores, this.basicScoreType),
          size: 'large',
        },
      ]
    },

    complexScores() {
      const { scores, globalScores } = this.scoreResult

      return [
        {
          label: `Nota ${this.isIrt ? 'TRI' : 'Padronizada'}`,
          size: 'x-large',
          text: this.getFormattedScore(scores, this.advancedScoreType),
          icon: {
            name: 'award',
            color: this.$tokens.color_primary,
          },
        },
        {
          label: 'Média Nacional',
          text: this.getFormattedScore(globalScores, this.advancedScoreType),
          size: 'large',
        },
      ]
    },

    simpleScoreboard() {
      if (this.isDigitalEnem) {
        const [score, globalScore] = this.complexScores

        return {
          main: score,
          footer: globalScore,
        }
      }

      return {
        main: this.mq_m ? this.basicScores : [this.basicScores[0]],
        footer: this.mq_m ? null : this.basicScores[1],
      }
    },

    compositeScoreboard() {
      const { globalScores } = this.scoreResult
      const { lowestScore, highestScore } = this.getScore(
        globalScores,
        this.advancedScoreType,
      )

      return {
        main: [...this.complexScores, ...this.basicScores],
        footer: [
          {
            label: 'Maior Nota Nacional',
            text: this.formatScore(highestScore),
            size: 'medium',
          },
          {
            label: 'Menor Nota Nacional',
            text: this.formatScore(lowestScore),
            size: 'medium',
          },
        ],
      }
    },

    scoreboard() {
      return this.isCompositeScoreboard
        ? this.compositeScoreboard
        : this.simpleScoreboard
    },
  },

  created() {
    this.loadScore()
  },

  methods: {
    ...mapActions([
      'getScoreResult',
      'getScoreResultByAssessment',
    ]),

    reload() {
      this.loadScore(true)
    },

    async loadScore(force) {
      this.hasError = false
      this.loading = true

      let params = null
      let action = null

      try {
        if (this.isDigitalEnem) {
          params = {
            assessmentId: this.assessment.id,
            force,
          }
          action = 'getScoreResultByAssessment'
        } else {
          const { examId } = this

          params = {
            examId,
            force,
          }
          action = 'getScoreResult'
        }
        let result = await this[action](params)

        if (this.isDigitalEnem) {
          result = {
            assessmentId: result.assessmentId,
            globalScores: result.globalScores
              .filter(({ name }) => name === 'Geral')
              .map((globalScore) => ({
                ...globalScore,
                name: 'irt',
              })),
            scores: result.scores
              .filter((score) => score.name === 'Geral')
              .map((score) => ({
                ...score,
                name: 'irt',
              })),
          }
        }

        this.scoreResult = result
      } catch (error) {
        this.hasError = true
      } finally {
        this.loading = false
      }
    },

    getFormattedScore(scores, scoreType) {
      const { score } = this.getScore(scores, scoreType)

      return this.formatScore(score)
    },

    getScore(scores, scoreType) {
      return scores.find((score) => score.name.toLowerCase() === scoreType)
    },

    formatScore(score) {
      return score.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
  },
}
</script>
