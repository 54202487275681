var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sas-table-container"},[_vm._t("list-info"),_c('Box',{staticClass:"box-sas-table",attrs:{"elevation":_vm.elevation,"padding":"0"}},[(_vm.loading)?_c('div',{staticClass:"container-loading"},[_c('Box',{attrs:{"elevation":10,"padding":"10px"}},[_c('spinner',{attrs:{"size":"large"}}),_vm._v(" "+_vm._s(_vm.$t('components.sPaginatedTable.loading'))+" ")],1)],1):_vm._e(),_c('table',{staticClass:"sas-table",class:{ 'always-show-head': _vm.alwaysShowHeader }},[(_vm.fields.length)?_c('thead',{staticClass:"sas-table__header"},[_c('tr',[(_vm.showIndex)?_c('th'):_vm._e(),(_vm.selectable)?_c('th',{staticClass:"data-table__header__checkbox"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.allSelected},on:{"click":function($event){return _vm.$emit('select-all')}}})]):_vm._e(),_vm._l((_vm.fields),function(field,index){return _c('th',{key:index,staticClass:"data-table__column",class:[
              { '--sortable': field.sortable },
              field.responsive,
              field.customClass ],style:(field.headerStyle),on:{"click":function($event){field.sortable ? _vm.updateSort(field.value) : null}}},[_c('div',{staticClass:"sas-table__header__field",style:({
                'justify-content': field.textAlign || '',
              })},[_vm._v(" "+_vm._s(field.text)+" "),(field.value === _vm.sortField && field.sortable)?_c('icon',{class:[
                  'sas-table__header__field__icon',
                  ("--" + _vm.sortDirection) ],attrs:{"size":"16","type":"arrow-down"}}):_vm._e(),(field.additionalInfo)?_vm._t(((field.value) + "-additional-info")):_vm._e()],2)])})],2)]):_vm._e(),(_vm.loading)?_c('tbody',_vm._l((_vm.skeletonLines),function(index){return _c('tr',{key:index},[(_vm.selectable)?_c('td',[_c('input',{attrs:{"type":"checkbox"}})]):_vm._e(),_vm._l((_vm.fields),function(field,indexTd){return _c('td',{key:indexTd,staticClass:"data-table__column",class:field.responsive,style:({ 'text-align': field.textAlign || '' })},[_c('skeleton-loader',{attrs:{"height":"16px","width":"100%"}})],1)})],2)}),0):_c('tbody',{staticClass:"sas-table__body"},_vm._l((_vm.content),function(row,index){return _c('tr',{key:index,class:[
            ("row-" + (_vm.getRowClass(row))),
            {
              '--selected': _vm.selectedRows
                .map(function (m) { return m[_vm.identifier]; })
                .includes(row[_vm.identifier]),
              'additional-info': _vm.hasAdditionalFields && index === 0,
            } ],attrs:{"tabindex":index},on:{"click":function($event){return _vm.$emit('click-row', row)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('click-row', row)}}},[(_vm.showIndex)?_c('td',{staticClass:"data-table__body__index"},[_vm._v(" "+_vm._s(index + 1)+" ")]):_vm._e(),(_vm.selectable)?_c('td',{staticClass:"data-table__body__checkbox"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedRows
                .map(function (m) { return m[_vm.identifier]; })
                .includes(row[_vm.identifier])},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('select-row', row)}}})]):_vm._e(),_vm._l((_vm.fields),function(field,indexTd){return _c('td',{key:indexTd,staticClass:"data-table__column",class:[
              field.responsive,
              field.customClass ],style:(field.style),attrs:{"id":((field.value) + "-" + index)}},[(!_vm.$scopedSlots[field.value])?[(_vm.formatData)?[_vm._v(" "+_vm._s(_vm.formatData(row[field.value]))+" ")]:[_c('span',{attrs:{"title":field.style && field.style.textOverflow
                    ? row[field.value] : ''}},[_vm._v(" "+_vm._s(row[field.value])+" ")])]]:_vm._t(field.value,null,{"row":field.index ? {
                row: row,
                index: index,
              } : row})],2)})],2)}),0),(_vm.footer.length)?_c('tfoot',{staticClass:"sas-table__footer"},[_vm._t("footer")],2):_vm._e()])]),(_vm.paginate)?_c('SPagination',{staticClass:"sas-pagination__container",attrs:{"items-per-page":_vm.itemsPerPage,"page":_vm.page.page,"total-items":_vm.page.totalItems},on:{"page-change":function($event){return _vm.pageChange()},"range-change":function($event){return _vm.rangeChange()}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t("pagination",null,{"end":props.end,"start":props.start,"total":props.total})]}}],null,true)}):_vm._e(),(_vm.paginate && _vm.loadMore)?_c('s-button',{staticClass:"show-more-pagination",attrs:{"disabled":_vm.disablePagination},on:{"click":function($event){return _vm.loadChange()}}},[_vm._v(" Mostrar mais ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }