<template>
  <DataTable
    :data="ranking"
    :density="mq_s ? 'small' : 'large'"
    :error="hasError"
    :fields="categoryFields"
    :freeze-first-column="mq_s"
    :loading="loading"
    @refresh="reload()"
  >
    <template
      slot="name"
      slot-scope="{ row }"
      class="teste"
    >
      <div class="category-name">
        {{ row.name }}

        <template
          v-if="
            !mq_m
              && !isHighSchool
              && categoriesNotPresentInResult.includes(row.name)
          "
        >
          <Icon
            v-tooltip="
              `${$tc('components.categoryPerformance.tooltip', row.name)}`
            "
            :stroke="$tokens.color_danger"
            type="info"
          />
        </template>
      </div>
    </template>

    <template
      slot="grade"
      slot-scope="{ row }"
    >
      {{ formatGrade(row.grade) }}
    </template>

    <template
      slot="schoolRanking"
      slot-scope="{ row }"
    >
      {{ row.schoolRanking }}º {{ mq_s ? '' : 'lugar' }}
    </template>

    <template
      slot="globalRanking"
      slot-scope="{ row }"
    >
      {{ row.globalRanking }}º {{ mq_s ? '' : 'lugar' }}
    </template>
  </DataTable>
</template>

<script>
import { mapActions } from 'vuex'

import DataTable from '@/components/DataTable'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'RankedCategoryRanking',

  components: {
    DataTable,
  },

  mixins: [mediaQueries],

  props: {
    isHighSchool: {
      type: Boolean,
      required: true,
    },

    assessmentId: {
      type: [Number, String],
      required: true,
    },

    examId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      categoriesNotPresentInResult: 'Filosofia e Arte',
      categoriesPerformance: [],
      categoriesRanking: [],
      loading: false,
      hasError: false,
      categoryFields: [
        {
          text: 'Disciplina',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Nota',
          value: 'grade',
          sortable: true,
        },
        {
          text: 'Escola',
          value: 'schoolRanking',
          sortable: true,
        },
        {
          text: 'Nacional',
          value: 'globalRanking',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    ranking() {
      return this.categoriesPerformance.map((categoryPerformance) => {
        const categoryRanking = this.categoriesRanking.find(
          (category) => category.name === categoryPerformance.name,
        )

        return {
          name: categoryPerformance.name,
          grade: categoryPerformance.grade,
          schoolRanking: categoryRanking.schoolRanking,
          globalRanking: categoryRanking.globalRanking,
        }
      })
    },
  },

  created() {
    this.loadCategoriesRank()
  },

  methods: {
    ...mapActions(['getPerformanceByCategories', 'getCategoriesRanking']),

    reload() {
      this.loadCategoriesRank(true)
    },

    async loadCategoriesRank(force) {
      this.hasError = false
      this.loading = true
      try {
        const { assessmentId, examId } = this
        const params = {
          assessmentId,
          examId,
          force,
        }
        const [categoriesPerformance, categoriesRanking] = await Promise.all([
          this.getPerformanceByCategories(params),
          this.getCategoriesRanking(params),
        ])
        this.categoriesPerformance = categoriesPerformance
        this.categoriesRanking = categoriesRanking
      } catch (error) {
        this.hasError = true
      } finally {
        this.loading = false
      }
    },

    formatGrade(score) {
      const decimalModifier = 10

      // eslint-disable-next-line no-undefined
      return (decimalModifier * score).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
  },
}
</script>

<style lang="sass">

.category-name
  display: flex
  align-items: center

  i
    margin-left: 8px

</style>
