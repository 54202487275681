var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{attrs:{"data":_vm.ranking,"density":_vm.mq_s ? 'small' : 'large',"error":_vm.hasError,"fields":_vm.categoryFields,"freeze-first-column":_vm.mq_s,"loading":_vm.loading},on:{"refresh":function($event){return _vm.reload()}},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"category-name"},[_vm._v(" "+_vm._s(row.name)+" "),(
          !_vm.mq_m
            && !_vm.isHighSchool
            && _vm.categoriesNotPresentInResult.includes(row.name)
        )?[_c('Icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            ("" + (_vm.$tc('components.categoryPerformance.tooltip', row.name)))
          ),expression:"\n            `${$tc('components.categoryPerformance.tooltip', row.name)}`\n          "}],attrs:{"stroke":_vm.$tokens.color_danger,"type":"info"}})]:_vm._e()],2)]}},{key:"grade",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatGrade(row.grade))+" ")]}},{key:"schoolRanking",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(row.schoolRanking)+"º "+_vm._s(_vm.mq_s ? '' : 'lugar')+" ")]}},{key:"globalRanking",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(row.globalRanking)+"º "+_vm._s(_vm.mq_s ? '' : 'lugar')+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }