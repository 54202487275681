<template>
  <InfoCard
    :error="hasError"
    title="Seu posicionamento"
    @refresh="$emit('refresh')"
  >
    <div class="result-percentile-wrapper">
      <transition
        appear
        mode="out-in"
        name="fade"
      >
        <div
          v-if="loading"
          class="result-percentile__loading"
        >
          <Spinner />
        </div>

        <div
          v-else
          :class="[
            'result-percentile',
            { '--medium-size': !largeSize },
          ]"
        >
          <span
            key="text"
            class="result-percentile__text"
            v-html="explanationText"
          />

          <Info
            v-if="isHighSchool && largeSize"
            key="info"
            class="result-percentile__info"
            :color="$tokens.color_ink_lighter"
            icon="info"
            padding="0"
            size="x-small"
            :text="`${$t('components.rankedPlacement.info')}`"
          />

          <div
            key="chart"
            class="result-percentile__chart"
          >
            <div
              v-if="largeSize"
              class="result-percentile__chart-legend"
            >
              <ChartLegend>Você</ChartLegend>

              <ChartLegend :color="$tokens.color_ink_lighter">
                Demais alunos
              </ChartLegend>
            </div>

            <VerticalBarChart
              :bar-width="chartConfig.barWidth"
              :bars="histogramTracks"
              :gutter="chartConfig.gutter"
              :height="chartConfig.height"
              :total="100"
              :x-label="chartConfig.xLabel"
              :y-label="chartConfig.yLabel"
            />

            <div
              v-if="!largeSize"
              class="result-percentile__chart-legend"
            >
              <ChartLegend>Seu posicionamento</ChartLegend>

              <ChartLegend :color="$tokens.color_ink_lighter">
                Demais alunos
              </ChartLegend>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </InfoCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import InfoCard from '@/components/InfoCard'
import VerticalBarChart from '@/components/VerticalBarChart'
import ChartLegend from '@/components/ChartLegend'
import Info from '@/components/Info'

import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'RankedPlacement',

  components: {
    InfoCard,
    VerticalBarChart,
    Info,
    ChartLegend,
  },

  mixins: [mediaQueries],

  props: {
    isHighSchool: {
      type: Boolean,
      required: true,
    },

    largeSize: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
  },

  data() {
    return {
      studentHistogram: [],
      loading: false,
      hasError: false,
    }
  },

  computed: {
    ...mapGetters([
      'assessment',
      'isDigitalEnem',
      'exam',
    ]),

    isStandardHistogram() {
      return this.assessment.typeSlug === 'unicamp'
    },

    histogramTracks() {
      return this.studentHistogram.map((data) => {
        const { lowerBound, upperBound } = data
        const lowerBoundLabel = lowerBound.toLocaleString('pt-br')
        const upperBoundLabel = upperBound.toLocaleString('pt-br')

        return {
          value: data.percentage,
          label: `${lowerBoundLabel} a ${upperBoundLabel}`,
          color: data.selected
            ? this.$tokens.color_primary
            : this.$tokens.color_ink_lighter,
          selected: data.selected,
        }
      })
    },

    selectedHistogramTrack() {
      return this.histogramTracks.find((data) => data.selected)
    },

    gutterSize() {
      if (!this.largeSize) {
        return '20px'
      }

      return (
        {
          small: this.$tokens.size_xs,
          x_small: this.$tokens.size_xxs,
        } || this.$tokens.size_s
      )
    },

    chartConfig() {
      let config = null

      if (this.largeSize) {
        config = {
          height: this.mq_m ? '144px' : '200px',
          xLabel: 'Nota',
          yLabel: this.mq_s ? '' : 'Alunos',
          barWidth: this.mq_s ? '0.5fr' : '1fr',
          gutter: this.gutterSize[this.$mq],
        }
      } else {
        config = {
          height: '144px',
          xLabel: '',
          yLabel: '',
          barWidth: '56px',
          gutter: this.$tokens.size_l,
        }
      }

      return config
    },

    explanationText() {
      if (this.selectedHistogramTrack) {
        const percentaFormatted = this.formatPercentage(
          this.selectedHistogramTrack.value,
        )
        const selectedHistogramTrack = this.selectedHistogramTrack.label

        if (this.largeSize) {
          return `Você está entre os ${percentaFormatted}% que tiraram de
            ${selectedHistogramTrack} na avaliação`
        }

        return `Você está entre os <span class="--highlight">${percentaFormatted}% dos alunos </span>
            que<br/>obtiveram nota entre ${selectedHistogramTrack} nesta prova.`
      }

      return ''
    },
  },

  watch: {
    exam() {
      this.reload()
    },
  },

  created() {
    this.loadPlacement()
  },

  methods: {
    ...mapActions([
      'getHistogram',
      'getStandardHistogram',
      'getIrtHistogramByAssessment',
    ]),

    reload() {
      this.loadPlacement(true)
    },

    async loadPlacement(force = false) {
      this.hasError = false
      this.loading = true
      try {
        let params = null
        let action = null

        const { typeSlug } = this.assessment

        if (this.isDigitalEnem || typeSlug === 'provinha') {
          params = {
            assessmentId: this.assessment.id,
            force,
          }
          action = 'getIrtHistogramByAssessment'
        } else {
          const { id } = this.exam
          params = {
            examId: id,
            force,
            isHighSchool: this.isHighSchool,
          }
          action = 'getStudentHistogram'
        }

        this.studentHistogram = await this[action](params)
      } catch (error) {
        this.hasError = true
      } finally {
        this.loading = false
      }
    },

    async getStudentHistogram(params) {
      return this.isStandardHistogram
        // eslint-disable-next-line no-return-await
        ? await this.getStandardHistogram(params)
        // eslint-disable-next-line no-return-await
        : await this.getHistogram(params)
    },

    formatPercentage(percentage) {
      const decimalModifier = 10

      return (Math.round(percentage * decimalModifier) / decimalModifier).toLocaleString('pt-br')
    },
  },
}
</script>

<style lang="sass">
$vertical-bar-letter-spacing: 0
$custom-font-size-s: 9px
$custom-font-size-xs: 8px
$color-highlight: #438DE4

.result-percentile
  padding: 16px
  box-sizing: border-box
  display: grid
  grid-template-columns: 1fr 1.1fr
  grid-template-areas: "chart ." "chart text" "chart ." "chart info" "chart ."
  grid-gap: 24px 80px

  &.--medium-size
    grid-template-areas: "text" "chart"
    grid-gap: 0

    .result-percentile__chart-legend
      margin-top: 30px
      justify-content: center

    .result-percentile__text
      text-align: center
      background: transparent
      width: 100%
      padding: 8px 0

      .--highlight
        font-size: $font-size-m
        font-weight: $font-weight-semi-bold
        color: $color-highlight

    .vertical-bar-chart
      &__bars
        +mq-l
          grid-template-columns: repeat(5, 48px) !important
          column-gap: 16px !important
          padding: 0 12px

        +mq(860px)
          column-gap: 12px !important
          padding: 0

  .vertical-bar-chart__bars
    small
      letter-spacing: $vertical-bar-letter-spacing

    +mq-s
      small
        font-size: $custom-font-size-s
        letter-spacing: .1px

    +mq-xs
      small
        font-size: $custom-font-size-xs

  +mq-l
    grid-template-columns: 100%
    grid-template-areas: "text" "chart" "info"
    padding: 8px

  +mq-s
    padding: 0
    gap: 32px

  &-wrapper
    width: 100%

  &__chart
    grid-area: chart

    &-legend
      display: flex

      .sas-chart-legend ~ .sas-chart-legend
        margin-left: 48px

      +mq-s
        display: block

        .sas-chart-legend ~ .sas-chart-legend
          margin-left: 0
          margin-top: 8px

  &__info
    grid-area: info

  &__text
    display: block
    box-sizing: border-box
    background: transparentize($color-ink-lighter, .8)
    padding: 8px 16px
    font-size: 16px
    font-weight: 600
    width: 77%
    border-radius: 8px
    color: $color-ink-light
    grid-area: text
    +mq-l
      width: 100%
    +mq-s
      font-size: 14px

  &__loading
    width: 100%
    +flex-center
    padding: 80px
    box-sizing: border-box
</style>
