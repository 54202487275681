<template>
  <div>
    <ExamResultProvinha
      v-if="examResult.typeSlug === 'provinha'"
    />

    <ExamResultOlympics
      v-else-if="examResult.typeSlug === 'olimpiadas'"
    />

    <ExamResultGeneralRanked
      v-else-if="isRankedAssessment"
      :application-id="applicationId"
      :assessment-id="assessmentId"
      :exam-id="examId"
    />

    <ExamResultGeneralBasic
      v-else
      :application-id="applicationId"
      :assessment-id="assessmentId"
      :exam-id="examId"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ExamResultGeneralBasic from '@/components/ExamResultGeneralBasic'
import ExamResultGeneralRanked from '@/components/ExamResultGeneralRanked'
import ExamResultProvinha from '@/components/ExamResultProvinha'
import ExamResultOlympics from '@/components/ExamResultOlympics'

export default {
  name: 'ExamResultGeneral',

  components: {
    ExamResultGeneralBasic,
    ExamResultGeneralRanked,
    ExamResultProvinha,
    ExamResultOlympics,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => vm.$emit('select-tab', vm.$route.name))
  },

  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },

    applicationId: {
      type: [Number, String],
      required: true,
    },

    examId: {
      type: [Number, String],
      required: true,
    },
  },

  computed: {
    ...mapGetters(['isRankedAssessment', 'assessment']),
    examResult() {
      const { typeSlug } = this.assessment

      return {
        typeSlug,
      }
    },
  },
}
</script>
