export default [
  {
    target: '#button-tour-step',
    content: 'Consulte quais foram os seus assuntos de maior dificuldade neste simulado na página de Exercícios Personalizados.',
    params: {
      highlight: true,
      enableScrolling: false,
      placement: 'bottom-end',
    },
    visibleOnBreakpoints: ['x_large'],
  },
  {
    target: '#banner-tour-step',
    content: 'Consulte quais foram os seus assuntos de maior dificuldade neste simulado na página de Exercícios Personalizados.',
    params: {
      highlight: true,
      enableScrolling: true,
      placement: 'top',
    },
    visibleOnBreakpoints: [
      'x_small',
      'small',
      'medium',
      'large',
    ],
  },
]
