<template>
  <div class="adaptive-selector">
    <AdaptiveSelectorDropdown
      v-bind="$props"
      v-on="$listeners"
    >
      <template
        slot="selectedOption"
        slot-scope="{ option }"
      >
        <slot
          name="selectedOption"
          :option="option"
        />
      </template>
      <template slot="skeleton">
        <slot name="skeleton" />
      </template>
      <template
        slot="option"
        slot-scope="{ option }"
      >
        <slot
          name="option"
          :option="option"
        />
      </template>
      <template slot="optionsTitle">
        <slot name="optionsTitle" />
      </template>
    </AdaptiveSelectorDropdown>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-properties */
import AdaptiveSelectorDropdown from './AdaptiveSelectorDropdown'

export default {
  name: 'AdaptiveSelector',
  components: {
    AdaptiveSelectorDropdown,
  },
  model: {
    prop: 'selectedOption',
    event: 'select',
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: [Object, String],
      default: null,
    },
    threshold: {
      type: Number,
      default: null,
    },
    optionsTitle: {
      type: String,
      default: null,
    },
    filterable: Boolean,
    filterPlaceholder: {
      type: String,
      default: null,
    },
    emptyString: {
      type: String,
      default: null,
    },
    emptyImage: {
      type: String,
      default: null,
    },
    groupBy: {
      type: String,
      default: null,
    },
    dropdownDirection: {
      type: String,
      default: 'right',
      validator: (value) => value.match(/(left|right)/),
    },
  },
  data() {
    return {
      searchString: '',
    }
  },
  computed: {
    isHorizontalList() {
      const isHorizontal = Number.isInteger(this.threshold)
        || (
          this.searchString.length === 0
          && this.options
          && this.options.length <= this.threshold
        )

      return isHorizontal
    },
  },
  watch: {
    options(options) {
      if (
        options
        && options.length
        && this.selectedOption === null
        && (
          this.isHorizontalList
          || (!this.isHorizontalList && !this.filterable)
        )
      ) {
        this.select(this.options[0])
      }
    },
  },
  mounted() {
    if (
      this.selectedOption === null
      && this.options
      && this.options.length) {
      this.select(this.options[0])
    }
  },
  methods: {
    select(value) {
      this.$emit('select', value)
    },
    search(searchString) {
      this.searchString = searchString
      this.$emit('search', searchString)
    },
  },
}
</script>
