<template>
  <div>
    <SyllabusTour />
    <Box
      id="banner-tour-step"
      :class="$style.redirectCard"
    >
      <SyllabusRedirectCardSkeleton v-if="syllabusIsLoading" />
      <template v-else>
        <header :class="$style.redirectCardHeader">
          <h3 :class="$style.redirectCardTitle">
            Exercícios personalizados
          </h3>
        </header>
        <section :class="$style.redirectCardContent">
          {{ cardText }}
        </section>
        <section
          :class="[
            $style.redirectCardFooter,
            { [$style.columnItems]: !hasSyllabusToCurrentAssessment },
          ]"
        >
          <img
            :class="$style.cardImage"
            :src="cardImage"
          >
          <SasButtom
            v-if="hasSyllabusError"
            :class="$style.button"
            @click="$emit('retry')"
          >
            Recarregar
          </SasButtom>
          <SasButtom
            v-else-if="hasSyllabusToCurrentAssessment || hasSyllabusToOtherAssessment"
            :class="$style.button"
            @click="goToSyllabus()"
          >
            {{ buttonText }}
          </SasButtom>
        </section>
      </template>
    </Box>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import isEmpty from 'lodash/isEmpty'

import hasSyllabusImage from '@/assets/redirectSyllabusCard/has-syllabus-state.svg'
import syllabusErrorImage from '@/assets/redirectSyllabusCard/syllabus-info-error.svg'
import noSyllabusImage from '@/assets/redirectSyllabusCard/no-syllabus-state.svg'

import SasButtom from '@/components/global/SasButtom'

import SyllabusRedirectCardSkeleton from './SyllabusRedirectCardSkeleton'
import mediaQueries from '@/mixins/mediaQueries'
import SyllabusTour from '../SyllabusTour'
import urls from '@/utils/urls'

export default {
  name: 'SyllabusRedirectCard',
  components: {
    SasButtom,
    SyllabusRedirectCardSkeleton,
    SyllabusTour,
  },
  mixins: [mediaQueries],
  props: {
    assessmentId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      hasSyllabusToCurrentAssessmentText: 'Bora treinar os assuntos de dificuldade com questões preparadas só para você se sair ainda melhor na próxima avaliação!',
      hasSyllabusToOtherAssessmentText: 'Ainda estamos preparando exercícios personalizados para você, mas você pode conferir outros exercícios que estão disponíveis para você praticar!',
      noAvailableSyllabusText: 'Ainda estamos preparando exercícios personalizados para você, aguarde que em breve nós traremos uma experiência incrível para você reforçar as dificuldades.',
      errorMessage: 'Ocorreu um erro com o carregamento dos exercícios personalizados, recarregue para tentar novamente',
      syllabusBaseURL: urls.ASSESSMENTS_INSIGHTS_URL,
    }
  },
  computed: {
    ...mapState({
      syllabusIsLoading: (state) => state.syllabus.isLoading,
      hasSyllabusError: (state) => state.syllabus.error,
    }),
    ...mapGetters([
      'hasSyllabus',
      'syllabusAssessmentIds',
    ]),
    hasSyllabusToCurrentAssessment() {
      return !this.syllabusIsLoading
        && this.hasSyllabus
        && this.syllabusAssessmentIds.includes(this.assessmentId)
    },
    hasSyllabusToOtherAssessment() {
      return !this.syllabusIsLoading
        && this.hasSyllabus
        && !isEmpty(this.syllabusAssessmentIds)
        && this.syllabusAssessmentIds.every((id) => id !== this.assessmentId)
    },
    noAvailableSyllabus() {
      return !this.isLoading && isEmpty(this.syllabusAssessmentIds)
    },
    cardText() {
      if (this.hasSyllabusError) {
        return this.errorMessage
      }
      if (this.noAvailableSyllabus) {
        return this.noAvailableSyllabusText
      }

      if (this.hasSyllabusToOtherAssessment) {
        return this.hasSyllabusToOtherAssessmentText
      }

      if (this.hasSyllabusToCurrentAssessment) {
        return this.hasSyllabusToCurrentAssessmentText
      }

      return ''
    },
    cardImage() {
      if (this.hasSyllabusError) {
        return syllabusErrorImage
      }

      return this.hasSyllabusToCurrentAssessment
        ? hasSyllabusImage
        : noSyllabusImage
    },
    buttonText() {
      if (this.hasSyllabusToCurrentAssessment) {
        return 'Ver trilhas'
      }
      if (this.hasSyllabusToOtherAssessment) {
        return 'Ver outros exercícios'
      }

      return ''
    },
    buttonLink() {
      if (this.hasSyllabusToCurrentAssessment) {
        const { assessmentId } = this
        const currentAssessmentSyllabus = `${this.syllabusBaseURL}/${assessmentId}/trilhas-recomendadas`

        return currentAssessmentSyllabus
      }

      if (this.hasSyllabusToOtherAssessment) {
        return this.syllabusBaseURL
      }

      return ''
    },
  },

  methods: {
    goToSyllabus() {
      window.open(this.buttonLink)
    },
  },
}
</script>

<style lang="scss" module>
.redirectCard {
  display: flex;
  flex-direction: column;
  min-width: 255px;
  background: #fff;
  background-image: url(~@/assets/triangle-shadow.png) !important;
  background-repeat: no-repeat !important;
  background-position: right 150% !important;
  transition: background-image 0.2s ease-in-out;
  overflow-y: visible;
  z-index: 1028;
}

.redirectCardHeader {
  margin-bottom: $size-s;
}

.redirectCardTitle {
  line-height: 1;
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-heading-3;
  color: $color-ink-light;

  @include mq-m {
    font-size: 18px;
  }
}
.redirectCardContent {
  margin-bottom: $size-s;
}

.redirectCardFooter {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 3;
  justify-content: space-between;
  align-items: flex-end;

  &.columnItems {
    @include mq-m__mf {
      flex-direction: column;

      & .cardImage {
        align-self: center;
        margin-bottom: $size-s;
      }
    }
  }
}
</style>
