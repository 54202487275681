<template>
  <Box
    class="exam-results__performance-card"
    padding="0"
  >
    <slot name="top" />

    <div
      v-if="loading"
      class="performance-card__loading"
    >
      <Spinner />
    </div>

    <div
      v-else
      class="performance-card__body"
    >
      <div class="performance-card__left">
        <PerformanceCategory
          :category="performanceCategory.name"
          :general-performance="{
            value: `${$tc(
              'components.performanceCategoryCard.total',
              performanceCategory.hitsCount,
              performanceCategory.itemsCount,
            )}`,
            label: 'Você acertou',
          }"
          :performance="{
            value: getPercentage(performanceCategory.grade),
            label: 'Acertos',
            color: color,
          }"
        />

        <SasButton
          v-if="performanceCategory.subcategories.length"
          class="performance-card__toggle"
          theme="float"
          @click="toggleSubcategories()"
        >
          <Icon
            slot="left"
            :type="subcategoriesVisibility ? 'minus-circle' : 'plus-circle'"
          />

          <div class="performance-card__toggle-label">
            Ver Práticas de Linguagem
          </div>
        </SasButton>

        <div
          v-show="isSubCategoryVisible"
          class="performance-card__subcategories"
        >
          <PerformanceSubcategory
            v-for="subcategory in performanceCategory.subcategories"
            :key="subcategory.name"
            :performance="getPercentage(subcategory.grade)"
            :subcategory="subcategory.name"
          />
        </div>
      </div>

      <slot name="right" />
    </div>
  </Box>
</template>

<script>
import PerformanceCategory from '@/components/PerformanceCategory'

const PerformanceSubcategory = () => import('@/components/PerformanceSubcategory')

export default {
  components: {
    PerformanceCategory,
    PerformanceSubcategory,
  },

  props: {
    performanceCategory: {
      type: Object,
      default: () => ({
        name: '',
        hitsCount: 0,
        itemsCount: 0,
        grade: 0,
        globalGrade: 0,
        subcategories: [],
      }),
    },

    color: {
      type: String,
      default: () => '#4D8AF0',
    },

    loading: Boolean,
  },

  data() {
    return {
      subcategoriesVisibility: false,
    }
  },

  computed: {
    isSubcategoryVisible() {
      return this.subcategoriesVisibility
        && this.performanceCategory.subcategories.length
    },
  },

  methods: {
    toggleSubcategories() {
      this.subcategoriesVisibility = !this.subcategoriesVisibility
    },

    getBadgeText(itemsCount) {
      return `${itemsCount} ${itemsCount > 1 ? 'questões' : 'questão'} na prova`
    },

    getPercentage(grade) {
      const percentageModifier = 100

      return Math.round(grade * percentageModifier)
    },
  },
}
</script>

<style lang="sass">

.exam-results__performance-card
  padding: 0

  .performance-card__loading
    display: flex
    align-items: center
    justify-content: center
    height: 384px

  .performance-card__body
    display: flex
    justify-content: space-between
    padding: 16px
    box-sizing: border-box

  .performance-card__left
    width: 416px

  .performance-card__toggle
    margin-top: 40px
    margin-bottom: 16px

    &-label
      text-align: left

  .performance-card__subcategories
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))

</style>
