<template>
  <div
    v-if="totalPages > 0"
    class="sas-pagination"
  >
    <div class="sas-pagination__count">
      <slot
        :end="end"
        :start="start"
        :total="totalItems"
      >
        {{ paginationCount }}
      </slot>
    </div>
    <div
      class="sas-pagination__pages"
    >
      <SPageButton
        :disabled="page === 0"
        icon="arrow-left"
        :page="page - 1"
        :title="$t('commons.previous')"
        @page-change="pageChange()"
      />
      <slot
        :buttons="buttons"
        name="buttons"
      >
        <SPageButton
          v-for="(button, key) in buttons"
          :key="key"
          v-bind="button"
          @page-change="pageChange()"
        />
      </slot>
      <SPageButton
        :disabled="page === totalPages - 1"
        icon="arrow-right"
        :page="page + 1"
        :title="$t('commons.next')"
        @page-change="pageChange()"
      />
    </div>
  </div>
</template>

<script>
import SPageButton from './SPageButton'

export default {
  name: 'SPagination',
  components: {
    SPageButton,
  },
  props: {
    page: {
      type: Number,
      default: 0,
      validator: (page) => page >= 0,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
      validator: (itemsPerPage) => itemsPerPage > 0,
    },
    maxVisiblePages: {
      type: Number,
      default: 5,
      validator: (maxVisiblePages) => maxVisiblePages > 0,
    },
    totalItems: {
      type: Number,
      required: true,
      validator: (totalItems) => totalItems >= 0,
    },
    loading: Boolean,
  },
  computed: {
    start() {
      return this.page * this.itemsPerPage
    },
    end() {
      const end = this.start + this.itemsPerPage

      return this.totalItems < end ? this.totalItems : end
    },
    totalPages() {
      if (this.itemsPerPage === 0) {
        return 0
      }

      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    pages() {
      /* eslint-disable no-magic-numbers */
      if (this.totalPages === 1) {
        return [0]
      }

      const filteredPages = [...this.filteredPages || []]
      const pages = this.filteredPages ? [
        filteredPages[0] - 1 === 1 ? 1 : '...',
        ...filteredPages,
        filteredPages[filteredPages.length - 1] + 1 === this.totalPages - 2
          ? this.totalPages - 2
          : '...',
      ] : [...Array(this.totalPages - 2).keys()].map((page) => page + 1)

      return [
        0,
        ...pages,
        this.totalPages - 1,
      ]
    },
    filteredPages() {
      const diff = this.maxVisiblePages / 2
      const toFilterPages = [...Array(this.totalPages).keys()].slice(2, -2)
      if (toFilterPages.length > this.maxVisiblePages) {
        const diffFirst = this.page - toFilterPages[0]
        const diffLast = this.page - toFilterPages[toFilterPages.length - 1]

        if (diffFirst < diff) {
          return toFilterPages.slice(0, this.maxVisiblePages)
        }
        if (diffLast >= -diff) {
          return toFilterPages.slice(-this.maxVisiblePages)
        }

        return toFilterPages.filter((page) => {
          const diffPage = this.page - page

          return diffPage < 0 ? Math.abs(diffPage) <= diff : diffPage < diff
        })
      }

      return null
    },
    buttons() {
      return this.pages.map((page) => ({
        page,
        active: page === this.page,
        disabled: page === '...',
        text: this.buttonText(page),
        title: '',
        loading: this.loading && page === this.page,
      }))
    },
    paginationCount() {
      return this.$tc('commons.paginationCount', this.totalItems, {
        start: this.start + 1,
        end: this.end,
        total: this.totalItems,
      })
    },
  },
  methods: {
    pageChange(page) {
      if (page >= this.totalPages && page !== 0 && this.totalPages !== 0) {
        throw new Error(
          'page may be maximum the total number of pages minus one',
        )
      }
      this.rangeChange(page)
      this.$emit('page-change', page)
    },
    rangeChange(page) {
      const start = page * this.itemsPerPage
      let end = start + this.itemsPerPage
      end = this.totalItems < end ? this.totalItems : end
      this.$emit('range-change', start, end)
    },
    buttonText(page) {
      if (page === '...') {
        return page
      }

      return `${page + 1}`
    },
  },
}
</script>

<style lang="sass">
.sas-pagination
  margin-top: $size-l
  +flex-space-between

  &__count
    color: $color-ink-light

  &__pages
    +flex-center

  .sas-button
    +space-inset(0 $size-s)

    &.--active
      background: $color-ink
      color: $color-ink

  .sas-button
    margin-right: $size-xxs
</style>
