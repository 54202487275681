<template>
  <Box padding="0">
    <div class="scoreboard">
      <div
        v-for="(result, index) in results"
        :key="index"
        class="scoreboard__content"
      >
        <div
          v-if="loading"
          class="scoreboard__scores"
        >
          <div class="scoreboard__score--skeleton">
            <SkeletonLoader
              height="24px"
              :width="$mq === 'x_large'
                || $mq === 'large'
                || $mq === 'medium' ? '104px' : '88px'
              "
            />

            <SkeletonLoader
              :height="$mq === 'x_large'
                || $mq === 'large'
                || $mq === 'medium' ? '56px' : '32px'
              "
              :width="
                $mq === 'x_large'
                  || $mq === 'large'
                  || $mq === 'medium' ? '144px' : '136px'
              "
            />
          </div>

          <div class="scoreboard__score--skeleton">
            <SkeletonLoader
              height="16px"
              :width="$mq === 'x_large'
                || $mq === 'large'
                || $mq === 'medium' ? '120px' : '88px'
              "
            />

            <SkeletonLoader
              :height="$mq === 'x_large'
                || $mq === 'large'
                || $mq === 'medium' ? '32px' : '24px'
              "
              :width="$mq === 'x_large'
                || $mq === 'large'
                || $mq === 'medium' ? '96px' : '64px'
              "
            />
          </div>
        </div>

        <div
          v-else
          class="scoreboard__scores"
        >
          <div
            v-for="(score, index) in result.scores"
            :key="index"
            class="scoreboard__score"
          >
            <h4
              v-if="!score.label"
              class="scoreboard__title"
            >
              {{ result.title }}
            </h4>
            <SasInfo
              :icon="score.icon"
              :label="score.label"
              :value="{
                text: formatScore(score.value, 2),
                type: score.type,
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
import { mapActions } from 'vuex'
import SasInfo from '@/components/global/SasInfo'

export default {
  name: 'ExamResultScoreboard',
  components: {
    SasInfo,
  },

  props: {
    examId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      scoreResult: {
        scores: [
          {
            name: '',
            score: '',
          },
        ],
        globalScores: [
          {
            name: '',
            score: '',
            lowestScore: '',
            highestScore: '',
          },
        ],
      },
    }
  },

  computed: {
    results() {
      const { scores, globalScores } = this.scoreResult

      return [
        {
          title: 'Nota Bruta',
          scores: [
            {
              icon: {
                name: 'award',
                color: '#2B93B4',
              },
              value: this.getFormattedScore(scores, 'classic'),
              type: 1,
            },
            {
              label: 'Média Nacional',
              value: this.getFormattedScore(globalScores, 'classic'),
              type: 3,
            },
          ],
        },
        {
          title: 'Nota TRI',
          scores: [
            {
              icon: {
                name: 'award',
                color: '#2B93B4',
              },
              value: this.getFormattedScore(scores, 'irt'),
              type: 1,
            },
            {
              label: 'Média Nacional',
              value: this.getFormattedScore(globalScores, 'irt'),
              type: 3,
            },
          ],
        },
      ]
    },
  },

  created() {
    this.load()
  },

  methods: {
    ...mapActions(['getScoreResult']),

    async load() {
      this.loading = true
      this.scoreResult = await this.getScoreResult({ examId: this.examId })
      this.loading = false
    },

    formatScore(score, fractionDigit) {
      let newScore = 0
      if (typeof score === 'string') {
        newScore = Number(score.replace(',', '.'))
      }

      return newScore.toLocaleString('pt-br', {
        minimumFractionDigits: fractionDigit,
        maximumFractionDigits: fractionDigit,
      })
    },

    getFormattedScore(scores, scoreType) {
      if (this.loading) {
        return this.formatScore(0)
      }
      const { score } = this.getScore(scores, scoreType)

      return this.formatScore(score)
    },

    getScore(scores, scoreType) {
      return scores.find((score) => score.name === scoreType)
    },
  },
}
</script>

<style lang="sass">

.scoreboard
  display: grid
  grid-template-columns: 1fr 1fr

  +mq-m
    grid-template-columns: 1fr

  &__content
    box-sizing: border-box
    padding: 32px 40px

    +mq-l
      padding: 32px 24px

    +mq-m
      padding: 32px 48px

    +mq-s
      padding: 24px

  &__title
    color: $color-ink-light

  &__scores
    display: flex
    align-items: center

  &__score
    display: inline-flex
    flex-direction: column

    +mq-s

      h1
        font-size: 28px

      h3
        font-size: 20px

    .left__icon
      width: 36px
      height: 36px

    &:first-child
      width: 200px

      +mq-l
        width: fit-content

      +mq-m
        width: 192px

      +mq-s
        width: 136px

    &:nth-child(2)
      width: 120px

    .info-right__label
      font-size: 16px

      +mq-s
        font-size: 13px

      +mq-xs
        font-size: 10px

    &--skeleton
      display: inline-flex
      flex-direction: column

      &:first-child
        width: 200px

        +mq-l
          width: 152px

        +mq-m
          width: 192px

        +mq-s
          width: 136px

      .skeleton-bone ~ .skeleton-bone
        margin-top: 4px

  .scoreboard__score ~ .scoreboard__score,
  .scoreboard__score--skeleton ~ .scoreboard__score--skeleton
    margin-left: 80px

    +mq-l
      margin-left: 32px

    +mq-xs
      margin-left: 16px

  .scoreboard__content ~ .scoreboard__content
    border-left: 1px solid $color-ink-lightest

    +mq-m
      border-left: unset
      border-top: 1px solid $color-ink-lightest

</style>
