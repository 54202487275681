<template>
  <div class="assessment-questions-table">
    <h4>
      {{ $t('components.AssessmentQuestionsTable.headers.title') }}
    </h4>

    <div class="assessment-questions-table__questions-amount">
      {{ questionsCount }}
    </div>
    <div
      v-if="!typeSlugOlympics"
      class="assessment-questions-table__filters"
    >
      <div class="assessment-questions-table__filters__lectures">
        <label class="filter-label">
          {{ $t('commons.lecture') }}
        </label>
        <AdaptiveSelector
          v-model="selectedLecture"
          class="select-filter__lecture"
          :options="lectures"
          :options-title="$t('commons.lecture')"
          :selected-option="$t('commons.all')"
          @select="selectLecture($event)"
        >
          <template
            slot="selectedOption"
            slot-scope="{ option }"
          >
            {{ option }}
          </template>

          <template
            slot="option"
            slot-scope="{ option }"
          >
            <div class="menu-options">
              {{ option }}
            </div>
          </template>
        </AdaptiveSelector>
      </div>
    </div>
    <SPaginatedTable
      always-show-header
      :content="assessmentQuestions"
      :fields="questionFields"
      :loading="loadingQuestions"
      :skeleton-lines="10"
      @updateSort="sortChange($event)"
    >
      <template
        slot="order"
        slot-scope="question"
      >
        <div class="question__order">
          {{ $t('commons.questionIndex', [question.row.order]) }}
        </div>
        <div class="question__description">
          {{ question.row.wording }}
        </div>
      </template>
      <template
        slot="lecture"
        slot-scope="question"
      >
        <div class="question__lecture">
          {{ question.row.lecture }}
        </div>
      </template>
      <template
        slot="status"
        slot-scope="question"
      >
        <div class="question__answer">
          <SBadge
            class="student-status__badge"
            :class="question"
            :icon="questionStatusConfig[question.row.status].icon"
            :variation="questionStatusConfig[question.row.status].badgeStatus"
          />
        </div>
      </template>
      <template
        slot="actions"
        slot-scope="question"
      >
        <div class="action-button">
          <s-button
            icon="arrow-right"
            :size="sizeIconTable"
            variation="secondary"
            @click="selectQuestion(question.row)"
          />
        </div>
      </template>
    </SPaginatedTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SPaginatedTable from '@/components/SPaginatedTable'
import AdaptiveSelector from '@/components/AdaptiveSelector/AdaptiveSelector'

const ENUM_QUESTION_STATUS = {
  BLANK: 'BLANK',
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
}

const TRANSLATE_PREFIX = 'components.AssessmentQuestionsTable'

export default {
  name: 'AssessmentQuestionsTable',
  components: {
    SPaginatedTable,
    AdaptiveSelector,
  },
  data() {
    return {
      loadingQuestions: false,
      originalQuestionList: [],
      sortOptions: {
        field: 'lecture',
        direction: 'asc',
      },
      translatePrefix: 'pages.assessmentResultTeacher.performanceTab',
      questionFields: [
        {
          text: this.$t(`${TRANSLATE_PREFIX}.headers.question`),
          value: 'order',
          sortable: true,
          responsive: 'xs',
        },
        {
          text: this.$t(`${TRANSLATE_PREFIX}.headers.lecture`),
          value: 'lecture',
          sortable: true,
          responsive: 'xs',
        },
        {
          text: this.$t(`${TRANSLATE_PREFIX}.headers.answer`),
          value: 'status',
          sortable: true,
          responsive: 'xs',
          textAlign: 'center',
          style: {
            textAlign: 'center',
          },
          customClass: 'question-status',
        },
        {
          value: 'actions',
          responsive: 'xs',
        },
      ],
      selectedKnowledgeArea: this.$t('commons.general'),
      selectedLecture: this.$t('commons.all'),
      questionStatusConfig: {
        [ENUM_QUESTION_STATUS.BLANK]: {
          icon: 'minus',
          badgeStatus: 'neutral',
        },
        [ENUM_QUESTION_STATUS.CORRECT]: {
          icon: 'check',
          badgeStatus: 'success',
        },
        [ENUM_QUESTION_STATUS.INCORRECT]: {
          icon: 'x',
          badgeStatus: 'danger',
        },
      },
    }
  },
  computed: {
    ...mapGetters(['assessment']),
    questionsCount() {
      return this.$tc(
        'commons.questionsCount',
        this.originalQuestionList.length,
        { amount: this.originalQuestionList.length },
      )
    },
    filteredLecture() {
      return (
        this.selectedLecture
        && this.selectedLecture !== this.$t('commons.all')
      )
    },

    optionStatus() {
      return ({ markedOption, correctOption }) => markedOption === correctOption
    },

    assessmentQuestions() {
      let filteredQuestions = Object.assign([], this.originalQuestionList)

      if (this.filteredLecture) {
        filteredQuestions = filteredQuestions
          .filter((question) => question.lecture === this.selectedLecture)
      }

      if (filteredQuestions.length > 0) {
        filteredQuestions = this.sortQuestions(
          filteredQuestions,
          this.sortOptions,
        )
      }

      return filteredQuestions
    },
    knowledgeAreas() {
      return [
        this.$t('commons.general'),
        ...new Set(
          this.originalQuestionList.map((question) => question.examType),
        ),
      ]
    },
    lectures() {
      return [
        this.$t('commons.all'),
        ...new Set(
          this.originalQuestionList
            .filter(
              (question) => this.validateSelectedLecture(question),
            )
            .map((question) => question.lecture)
            .sort((a, b) => a.localeCompare(b)),
        ),
      ]
    },
    validateSelectedLecture() {
      return ({ examType }) => examType === this.selectedKnowledgeArea
        || (
          this.selectedKnowledgeArea === this.$t(
            'commons.general',
          )
        )
    },
    sizeIconTable() {
      return this.$mq.includes(['x_small'])
        ? 'small'
        : 'medium'
    },
    typeSlugOlympics() {
      const { typeSlug } = this.assessment

      return typeSlug === 'olimpiadas'
    },
  },

  created() {
    this.loadQuestions()
  },

  methods: {
    ...mapActions([
      'getQuestionsWordingByAssessmentId',
      'setSelectedQuestion',
      'setPagedQuestions',
      'getPerformanceItemsByAssessments',
      'getPerformanceByItems',
    ]),
    sortQuestions(questionsList, { field, direction }) {
      return questionsList.sort((currentQuestion, nextQuestion) => {
        if (typeof currentQuestion[field] === 'string') {
          if (direction === 'asc') {
            return currentQuestion[field].localeCompare(nextQuestion[field])
          }

          return nextQuestion[field].localeCompare(currentQuestion[field])
        }

        if (direction === 'asc') {
          return currentQuestion[field] - nextQuestion[field]
        }

        return nextQuestion[field] - currentQuestion[field]
      })
    },
    async loadQuestions() {
      this.loadingQuestions = true
      this.originalQuestionList = []

      try {
        const { assessmentId, examId } = this.$route.params
        const actionToCall = this.typeSlugOlympics
          ? {
            action: 'getPerformanceByItems',
            param: examId,
          }
          : {
            action: 'getPerformanceItemsByAssessments',
            param: assessmentId,
          }

        const { action, param } = actionToCall

        this.originalQuestionList = await this[action]([param])
        this.originalQuestionList = this.originalQuestionList.map(
          (question) => {
            const { markedOption, correctOption } = question

            return {
              ...question,
              status: this.getQuestionStatus({
                markedOption,
                correctOption,
              }),
            }
          },
        ).sort((current, next) => current.order - next.order)
      } finally {
        this.loadingQuestions = false
      }
    },
    sortChange(options) {
      this.sortOptions = options
    },
    getQuestionStatus({ markedOption, correctOption }) {
      if (markedOption === -1) {
        return ENUM_QUESTION_STATUS.BLANK
      }

      if (markedOption === correctOption) {
        return ENUM_QUESTION_STATUS.CORRECT
      }

      return ENUM_QUESTION_STATUS.INCORRECT
    },
    getQuestionWording(currentQuestion, questionsWording) {
      const wordingFound = questionsWording
        .find((question) => currentQuestion.itemId === question.itemId)

      return wordingFound && wordingFound.wording
        ? wordingFound.wording
        : ''
    },
    selectLecture(lecture) {
      this.selectedLecture = lecture || this.lectures[0]
    },
    selectArea(area) {
      this.selectedKnowledgeArea = area || this.knowledgeAreas[0]
      this.selectLecture()
    },
    selectQuestion(selectedQuestion) {
      const { itemId: currentQuestion, examId } = selectedQuestion
      this.setSelectedQuestion(selectedQuestion)
      this.setPagedQuestions(
        {
          currentQuestion,
          questions: this.originalQuestionList.map((question) => ({
            itemId: question.itemId,
            examId,
          })),
          markedOptions: this.originalQuestionList.map((question) => ({
            markedOption: question.markedOption,
            itemId: question.itemId,
          })),
        },
      )
    },
  },
}
</script>

<style lang="sass" scoped>
$question-order-color: #1E2124

.assessment-questions-table
  padding: $size-s 0

  +mq-m--mf
    padding: $size-l 0

  &__questions-amount
    margin-top: $size-xs
    font-size: $font-size-heading-6-small
    line-height: 24px
    color: $color-ink-light

  ::v-deep &__filters
    margin-top: $size-m
    display: flex
    flex-flow: column

    +mq-s--mf
      flex-flow: row

    &__lectures
      margin-top: $size-xs

    .filter-label
      font-size: $font-size-xs
      color: $color-ink-light
      margin: $size-xxs

      +mq-m--mf
        font-size: $font-size-s

    .adaptive-selector-dropdown__handle
      min-width: 160px

      &__caret
        margin-left: auto

  &__card-list
    margin-top: $size-m

  .sas-table-container
    margin-top: $size-m

    .data-table__column.question-status .sas-table__header__field
      justify-content: center

    .question
      &__order
        font-weight: 500
        font-size: $font-size-heading-6
        line-height: 24px
        color: $question-order-color

      &__description
        max-width: 100px
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        width: 200px
        font-size: $font-size-s
        line-height: 20px
        color: $color-ink-light

        +mq-m--mf
          max-width: 200px
</style>
