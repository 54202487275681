var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Box',{attrs:{"padding":"0"}},[_c('div',{staticClass:"scoreboard"},_vm._l((_vm.results),function(result,index){return _c('div',{key:index,staticClass:"scoreboard__content"},[(_vm.loading)?_c('div',{staticClass:"scoreboard__scores"},[_c('div',{staticClass:"scoreboard__score--skeleton"},[_c('SkeletonLoader',{attrs:{"height":"24px","width":_vm.$mq === 'x_large'
              || _vm.$mq === 'large'
              || _vm.$mq === 'medium' ? '104px' : '88px'}}),_c('SkeletonLoader',{attrs:{"height":_vm.$mq === 'x_large'
              || _vm.$mq === 'large'
              || _vm.$mq === 'medium' ? '56px' : '32px',"width":_vm.$mq === 'x_large'
                || _vm.$mq === 'large'
                || _vm.$mq === 'medium' ? '144px' : '136px'}})],1),_c('div',{staticClass:"scoreboard__score--skeleton"},[_c('SkeletonLoader',{attrs:{"height":"16px","width":_vm.$mq === 'x_large'
              || _vm.$mq === 'large'
              || _vm.$mq === 'medium' ? '120px' : '88px'}}),_c('SkeletonLoader',{attrs:{"height":_vm.$mq === 'x_large'
              || _vm.$mq === 'large'
              || _vm.$mq === 'medium' ? '32px' : '24px',"width":_vm.$mq === 'x_large'
              || _vm.$mq === 'large'
              || _vm.$mq === 'medium' ? '96px' : '64px'}})],1)]):_c('div',{staticClass:"scoreboard__scores"},_vm._l((result.scores),function(score,index){return _c('div',{key:index,staticClass:"scoreboard__score"},[(!score.label)?_c('h4',{staticClass:"scoreboard__title"},[_vm._v(" "+_vm._s(result.title)+" ")]):_vm._e(),_c('SasInfo',{attrs:{"icon":score.icon,"label":score.label,"value":{
              text: _vm.formatScore(score.value, 2),
              type: score.type,
            }}})],1)}),0)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }